import React from "react";
import { HeaderTitleWrapper, TitleDealer } from "./header-dealer.atom.style";

interface IProps {
  title?: string;
  informationAboutPhoto?: string;
}

export const HeaderDealerAtom: React.FC<IProps> = (props: IProps) => {
  return (
    <HeaderTitleWrapper>
      <TitleDealer>{props.title}</TitleDealer>
    </HeaderTitleWrapper>
  );
};
