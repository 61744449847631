import { ExpandLess, ExpandMore } from "@mui/icons-material";
import React, { useEffect, useState } from "react";

import { useChecklistContext } from "../../../hooks";
import { CheckListStatusEnum } from "../../../models";

import {
  ChecklistOption,
  ChecklistUl,
  ListItem,
  Wrapper,
} from "./checklist-status.atom.style";

type Option = {
  value: CheckListStatusEnum;
  label: string | React.ReactNode;
};

interface IChecklistStatusProps {
  status: CheckListStatusEnum;
  checklistStatus: CheckListStatusEnum | null;
  setToPassOn: (value: boolean) => void;
  requestedPrice: number;
  negotiatedPrice: number;
}

export function ChecklistStatus({
  status,
  checklistStatus,
  setToPassOn,
  requestedPrice,
  negotiatedPrice,
}: IChecklistStatusProps) {
  const { setStatusProposal } = useChecklistContext();
  const options = [
    {
      value: CheckListStatusEnum.Approved,
      label: <ListItem status="#4AA434">Aprovado</ListItem>,
      disabled: requestedPrice !== negotiatedPrice,
    },
    {
      value: CheckListStatusEnum.WithChanges,
      label: <ListItem status="#EEA200">c/ Alteração</ListItem>,
      disabled: requestedPrice === negotiatedPrice,
    },
    {
      value: CheckListStatusEnum.ToPassOn,
      label: <ListItem status="#FF0000">p/ Repasse</ListItem>,
      disabled: false,
    },
  ];

  const [selectedOption, setSelectedOption] = useState<Option | undefined>(
    checklistStatus !== null ? options[status as number] : undefined,
  );
  const [isOpen, setIsOpen] = useState(false);
  // const [error, setError] = useState<string | null>(null);

  const handleSelectChange = (option: Option) => {
    setStatusProposal(false);
    if (option.value === CheckListStatusEnum.ToPassOn) {
      setToPassOn(true);
    } else {
      setToPassOn(false);
    }
    setSelectedOption(option);
    setIsOpen(false);
  };

  useEffect(() => {
    if (selectedOption && status !== CheckListStatusEnum.ToPassOn) {
      setSelectedOption(options[status]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <Wrapper>
      <ChecklistOption onClick={() => setIsOpen(!isOpen)}>
        {selectedOption?.label}

        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ChecklistOption>

      {isOpen && (
        <ChecklistUl>
          {options.map((option, index) => (
            <li
              key={index}
              onClick={option.disabled ? undefined : () => handleSelectChange(option)}
              aria-disabled={option.disabled ? "true" : "false"}
            >
              {option.label}
            </li>
          ))}
        </ChecklistUl>
      )}
      {/* {error && <p style={{ color: "red" }}>{error}</p>} */}
    </Wrapper>
  );
}
