import React, { useCallback, useEffect, useState } from "react";
import {
  ActiveItemTypeConstant,
  AuthHelper,
  C4UMenu,
  CorporateUserTypeEnum,
  IAuth0User,
  ICoxUser,
  IDealershipComboData,
  IUser,
  StorageConstant,
} from "c4u-web-components";
// import { AuthTemplate } from "../auth/auth.template";
import { useTranslation } from "react-i18next";
import { AuthTemplate } from "../auth/auth.template";
import { AppContainerWithMenu, StyledContainer } from "./main.template.style";
import { useAuth0 } from "@auth0/auth0-react";
import { useC4u, useTradeinContext } from "../../../hooks";

export const MainTemplate: React.FC = (props) => {
  const { t } = useTranslation();

  const { isAuthenticated, isLoading } = useAuth0();
  const {
    GetAuth0User,
    GetAdminUser,
    GetDealership,
    postSelectDealership,
    getListMyDealerships,
  } = useC4u();
  const { setUserContext } = useTradeinContext();

  const [auth0User, setAuth0User] = useState<IAuth0User>();
  const [coxUser, setCoxUser] = useState<ICoxUser>();
  const [userCox, setUserCox] = useState<IUser>();
  const [selectedDealership, setSelectedDealership] =
    useState<IDealershipComboData>();
  const [redirectedFromLogin, setRedirectedFromLogin] = useState(false);

  const handleLogout = () => {
    window.location.href = process.env.REACT_APP_LOGOUT_URL ?? "";
  };

  const clearKeysFromStorage = useCallback(() => {
    sessionStorage.removeItem(StorageConstant.redirectedFromLogin);
  }, []);

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      GetAuth0User().then((t) => setAuth0User(t));
      GetAdminUser().then((t) => {
        setCoxUser(t);
        GetDealership(t.dealershipId ?? 0).then((data) => {
          setUserContext({ dealership: data, user: t });
        });
      });
    }
    // eslint-disable-next-line
  }, [isLoading, isAuthenticated]);

  useEffect(() => {
    if (coxUser && auth0User) {
      setUserCox(AuthHelper.GetCoxUser(coxUser, auth0User));
      const { dealershipId, dealershipName, dealershipCnpj } = coxUser;
      if (dealershipId && dealershipName && dealershipCnpj)
        setSelectedDealership({
          id: dealershipId,
          name: dealershipName,
          cnpj: dealershipCnpj,
        });
      setRedirectedFromLogin(
        sessionStorage.getItem(StorageConstant.redirectedFromLogin) === "true"
      );
    }
  }, [auth0User, coxUser]);

  return (
    <>
      <AuthTemplate>
        <C4UMenu
          handleLogout={handleLogout}
          activeMenu={ActiveItemTypeConstant.TRADEIN}
          environment={process.env.REACT_APP_BUILD}
          user={userCox}
          selectDealershipProps={
            !userCox
              ? undefined
              : {
                  userId: coxUser?.id,
                  userIsManager:
                    coxUser?.corporateUserType ===
                    CorporateUserTypeEnum.Manager,
                  userIsCorporate: coxUser?.isCorporate,
                  dealership: selectedDealership,
                  onSubmitCallback: postSelectDealership,
                  getDealershipsPagedListCallback: getListMyDealerships,
                  setSelectedDealership: setSelectedDealership,
                  onHideCallback: clearKeysFromStorage,
                  forceShowModal: redirectedFromLogin,
                }
          }
          translate={t}
        />
        <AppContainerWithMenu>
          <StyledContainer>{props.children}</StyledContainer>
        </AppContainerWithMenu>
      </AuthTemplate>
    </>
  );
};
