import styled from "styled-components";

export const ContainerCarousel = styled.div`
  width: 200px;
  height: 275px;

  .swiper-button-prev,
  .swiper-button-next {
    color: white;
    opacity: 0.8;
    font-size: 8px !important;
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    font-size: 20px;
  }

    @media (max-width: 641px) {
      width: 100px;
      height: 175px;
  }
`;
export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 275px;

  video {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

    @media (max-width: 641px) {
      height: 175px;
  }
`;

export const IconFrame = styled.div`
  position: absolute;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 80px;
  top: 123px;
  background-color: #00b2ff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
`;

export const ImageCar = styled.img`
  width: 100%;
  height: 275px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

    @media (max-width: 641px) {
      height: 175px;
  }
`;

export const ImageIcon = styled.img`
  width: 100px;
  height: 65px;
`;

export const ContainerIcon = styled.div`
  width: 100%;
  height: 275px;
  background-color: ${(props) => props.theme.colors.Grey30};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dee2e5;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

  @media (max-width: 641px) {
      height: 175px;
  }
`;
