import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { theme } from "c4u-web-components";
import { Suspense } from "react";
import { ThemeProvider } from "styled-components";
import { Routes } from "./routes";
import GlobalStyles from "./ui/styles";
import { SessionProvider, TradeinProvider } from "./contexts";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<div>Loading...</div>}>
        <SessionProvider>
          <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ""}
            clientId={process.env.REACT_APP_AUTH0_CLIENTID ?? ""}
            audience={process.env.REACT_APP_AUTH0_AUDIENCE ?? ""}
            scope={`${process.env.REACT_APP_AUTH0_SCOPE}`}
            redirectUri={`${window.location.href}`}
          >
            <TradeinProvider>
              <Routes />
            </TradeinProvider>
          </Auth0Provider>
        </SessionProvider>
      </Suspense>
      <GlobalStyles />
    </ThemeProvider>
  );
}

export default App;
