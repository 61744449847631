import styled, { css } from "styled-components";

interface StyledInputProps {
  readonly hasError: boolean
  readonly width: number
}

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  span {
    font-size: 10px;
    font-family: ${({ theme }) => theme.fontFamily.OpenSans};
    color: #c4390a;
  }
`

export const StyledInput = styled.input<StyledInputProps>`
  width: ${({ width }) => width}px;
  border: 1px solid #E1E1E2;
  outline: none;
  background: #fff;
  height: 33px;
  border-radius: 4px;
  padding: 0 12px;
  font-size: 12px;
  font-family: ${({ theme }) => theme.fontFamily.OpenSans};
  font-weight: 400;
  color: #4A4A4A;
  transition: border-color 0.2s ease-in;
  appearance: none;

  &:focus {
    border-color: ${({ theme }) => theme.colors.BlueNavy};
  }

  &[disabled] {
    background: #F9F9F9;
    border-color: #E1E1E2;
    cursor: not-allowed;
  }

  ${({ hasError }) => hasError && css`
    color: #c4390a;
    border-color: #c4390a;
    &:focus {
      border-color: #c4390a;
    }
  `}
`
