import React, { useContext } from "react";

import { ChecklistContext } from "../../contexts";
import { IGetDebtsResponse, IProposalDetailsResponse } from "../../models";

export interface IChecklistProviderState {
  checklistData: IProposalDetailsResponse;
  setChecklistData: React.Dispatch<IProposalDetailsResponse>;
  equipmentsCost: number;
  statusProposal: boolean;
  setStatusProposal: React.Dispatch<boolean>;
  setEquipmentsCost: React.Dispatch<number>;
  saveChecklistData: boolean;
  setSaveChecklistData: React.Dispatch<boolean>;
  renavamLoading: boolean;
  setRenavamLoading: React.Dispatch<boolean>;
  tradeInId: number;
  cleanState: () => void;
  debts: IGetDebtsResponse[];
  setDebts: React.Dispatch<IGetDebtsResponse[]>;
  searchDebts(renavam: string): Promise<void>;
  renavamInput: string;
  setRenavamInput: (renavam: string) => void;
  hasProblemConsultingDebts: boolean | null;
  setHasProblemConsultingDebts: React.Dispatch<boolean | null>;
}

export const useChecklistContext = () => {
  const context = useContext<IChecklistProviderState>(ChecklistContext);

  if (!context) {
    throw new Error("Empty context");
  }

  return context;
};
