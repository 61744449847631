import { Form } from "react-bootstrap";
import styled from "styled-components";

export const DatePickerFormGroupWrapper = styled(Form.Group)`
  margin-bottom: 1rem;

  .react-datepicker__day-name {
    width: 2.2rem;
  }

  .react-datepicker__day {
    width: 2.2rem;
  }
  .react-datepicker-popper {
    z-index: 2;
  }
`;
