import { TypesHelper } from "c4u-web-components";

import { TradeInProgress } from "../../enums/trade-in-progress";

export interface IGetPaginatedTradeInRequest {
  search?: string;
  startDate?: string | Date;
  finalDate?: string | Date;
  tradeInProgress?: TradeInProgress | "" | null;
  page: number;
  pageSize: number;
}

export class GetPaginatedTradeInRequest {
  search: string | null;
  startDate: string | null;
  finalDate: string | null;
  tradeInProgress: TradeInProgress | null;
  page: number | null;
  pageSize: number | null;

  constructor({
    search,
    startDate,
    finalDate,
    tradeInProgress,
    page,
    pageSize,
  }: IGetPaginatedTradeInRequest) {
    this.search = TypesHelper.toStringNull(search);
    this.startDate = TypesHelper.toDateTimeNull(startDate)?.toISOString() ?? null;
    this.finalDate = TypesHelper.toDateTimeNull(finalDate)?.toISOString() ?? null;
    this.tradeInProgress = TypesHelper.toNumberNull(
      tradeInProgress !== "" ? tradeInProgress : null,
    );
    this.page = TypesHelper.toNumberNull(page);
    this.pageSize = TypesHelper.toNumberNull(pageSize);
  }
}
export interface ICustomer {
  name: string;
  email: string;
  phone: string;
}
export interface IPurchaseVehicle {
  brandName: string;
  modelName: string;
  versionName: string;
  modelYear: number;
}
export interface ITradeInVehicleResponse {
  brandName: string;
  modelName: string;
  versionName: string;
  hp: number;
  modelYear: number;
  plate: string;
  requestedPrice: number;
}

export interface IPagedResponseBase {
  currentPage: number;
  totalPages: number;
  recordsPerPage: number;
  totalRecords: number;
}

export interface IGetPaginatedTradeInResponse {
  tradeInId: number;
  negotiatedValue: number;
  addedIn: string;
  tradeInProgress: TradeInProgress;
  tradeInVehicle: ITradeInVehicleResponse;
  customer: ICustomer;
  purchaseVehicle: IPurchaseVehicle;
}
export interface IGetPaginatedTradeInResponseModel extends IPagedResponseBase {
  data: IGetPaginatedTradeInResponse[];
}
