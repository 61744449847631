import styled from "styled-components";

export const ContainerChecklist = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  border: 1px solid #dee2e5;
  margin-bottom: 200px;
  background-color: #fff;
`;

export const Checklist = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 80%;
`;
export const BackContainer = styled.div`
  align-self: flex-end;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
