import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
`;
export const ContainerCardLead = styled.div`
  width: 110%;
  height: 275px;

  border: 1px solid #dee2e5;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 10px;
  gap: 10px;
`;

export const PhotosVehicle = styled.div`
  border-radius: 10px;
`;

export const VehicleAndUserInformation = styled.div`
  width: 25%;
  height: 270px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;

  @media (max-width: 1200px) {
    overflow: hidden;
    text-overflow: ellipsis;
    direction: ltr;
  }
  @media (max-width: 991px) {
    gap: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media (max-width: 818px) {
    gap: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 640px) {
    gap: 4px;
  }
`;

export const VehicleInformation = styled.span`
  font-family: ${props => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 10px;

  color: ${props => props.theme.colors.BlueNavy};

  @media (max-width: 918px) {
    font-size: 12px;
    line-height: 13px;
  }

  @media (max-width: 750px) {
    white-space: nowrap;
    font-size: 10px;
    line-height: 6px;
    text-overflow: ellipsis;
  }

  @media (max-width: 640px) {
    font-size: 6px;
  }
`;

export const OtherCarInformation = styled.span`
  font-family: ${props => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 10px;

  color: ${props => props.theme.colors.BlueNavy};

  @media (max-width: 1237px) {
    font-size: 14px;
    line-height: 10px;
  }

  @media (max-width: 991px) {
    font-size: 12px;
    line-height: 13px;
  }

  @media (max-width: 880px) {
    font-size: 10px;
  }

  @media (max-width: 780px) {
    font-size: 8px;
  }

  @media (max-width: 640px) {
    font-size: 4px;
  }
`;

export const VehicleInformationData = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 60px;

  @media (max-width: 960px) {
    gap: 20px;
  }
  @media (max-width: 880px) {
    gap: 10px;
  }
`;

export const DataVehicle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleGenericCard = styled.span`
  width: 100%;
  font-family: ${props => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  color: ${props => props.theme.colors.MediumGray};
  word-wrap: normal;

  @media (max-width: 1237px) {
    font-size: 10px;
    line-height: 30px;
  }

  @media (max-width: 991px) {
    font-size: 11px;
    line-height: 13px;
  }

  @media (max-width: 781px) {
    font-size: 10px;
    line-height: 13px;
  }
`;

export const DataUserInformation = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  height: 25px;
  overflow: hidden;

  path {
    fill: #4a4a4a;
  }
`;

export const ContactInformation = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  padding: 0px 0px 5px 0px;

  @media (min-width: 1600px) {
    width: 100%;
  }
`;

export const LineHr = styled.hr`
  height: 275px;
  margin: 0;
  border: 0;
  border-left: 2px solid #dee2e5;
  box-sizing: border-box;
`;

export const BoxVehicle = styled.div`
  width: 100%;
  height: 90px;
  padding: 25px 0 0 0;
`;

export const BoxContact = styled.div`
  width: 100%;
`;

export const BoxName = styled.div`
  white-space: nowrap;
  width: 280px;
  height: 25px;
  overflow: hidden;

  text-overflow: ellipsis;

  @media (max-width: 991px) {
    width: 120px;
    text-overflow: ellipsis;
  }
`;
