import React from "react";
import { InputContainer, StyledInput } from "./input.atom.styles";
import { NumberHelper } from "c4u-web-components";

interface IInputAtomProps extends Omit< React.InputHTMLAttributes<HTMLInputElement>, "value" | "onChange" > {
  value: string | number;
  onChange: (value: string | number) => void;
  width?: number;
  hasError?: boolean;
  errorMessage?: string;
  maskType?: 'currency';
}
export const InputAtom: React.FC<IInputAtomProps> = ({
  value,
  onChange,
  width = 100,
  hasError = false,
  errorMessage,
  maskType,
  ...rest
}) => {

  const handleChange = (value: string) => {
    const inputValue = value.replace(/[^\d]/g, '')
    const floatValue = parseFloat(inputValue) / 100
    return floatValue
  };

  switch(maskType) {
    case 'currency':
      return (
        <InputContainer>
          <StyledInput
            type="text"
            value={value !== '' ? NumberHelper.toFormatCurrency(value) : value}
            onChange={({ target }) => onChange(handleChange(target.value.toString()))}
            width={width}
            hasError={hasError}
            {...rest}
          />
          {hasError && <span>{errorMessage}</span>}
        </InputContainer>
      );
    default:
      return (
        <StyledInput
          type="text"
          value={value}
          onChange={({ target }) => onChange(target.value)}
          width={width}
          hasError={hasError}
          {...rest}
        />
      );
  }
};
