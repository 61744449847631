import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import { CoxIcon } from "c4u-web-components";
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useChecklistContext, useKbb } from "../../../hooks";
import { IColors, IEquipments, VehicleEquipment } from "../../../models";
import { InputAtom, Show } from "../../atoms";

import {
  ActionsContainer,
  ArmouringItem,
  ArmouringItems,
  ArmouringTitle,
  BoxError,
  BoxSwitch,
  CustomLabel,
  DamageButton,
  DamageTitle,
  DescriptionContainer,
  InformationAccording,
  MainAccording,
  RetryEquipments,
  SubTitleAccording,
  Title,
  TitleOptional,
  Typography,
  TypographySecond,
  Wrapper,
} from "./accordion.molecule.style";

interface IProps {
  setEquipments: (v: IEquipments[] | undefined) => void;
  equipments: IEquipments[];
  setColors: (v: IColors[] | undefined) => void;
  colors: IColors[];
  setTotalDamages: (total: number) => void;
  setEquipmentsCost: (total: number) => void;
  setPostChecklistData: (postChecklistData: any) => void;
  saveChecklistData: boolean;
  damageErrors: number[];
  setDamageErrors: Dispatch<SetStateAction<number[]>>;
  colorsEmptyErrors: boolean;
  setColorsEmptyErrors: Dispatch<SetStateAction<boolean>>;
}

type DamageListsTypes = Array<{
  title: number;
  withDamage: boolean;
  cost: number | null;
  description: string | null;
}>;

type DeliveryDocumentsTypes = Array<{
  item: number;
  checked: boolean | null;
  armouringOptions?: { label: string; checked: boolean }[];
}>;

export const AccordionMolecule: React.FC<IProps> = ({
  equipments,
  colors,
  setTotalDamages,
  setColors,
  setEquipmentsCost,
  setPostChecklistData,
  saveChecklistData,
  damageErrors,
  setDamageErrors,
  colorsEmptyErrors,
  setColorsEmptyErrors,
}) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const [error, setError] = useState<boolean>(false);

  const [equipmentsList, setEquipmentsList] = useState<
    {
      checked: boolean;
      equipmentId: number;
      equipmentName: string;
      equipmentPrice: number;
      equipmentSettled: boolean;
    }[]
  >(
    equipments.map(equip => ({
      ...equip,
      checked: equip.equipmentSettled,
    })),
  );

  const [activeSwitch, setActiveSwitch] = useState<boolean>(
    equipments.some(
      ({ equipmentId, equipmentSettled }) => equipmentId === 1000000 && equipmentSettled,
    ),
  );

  const { GetAllEquipments } = useKbb();
  const { checklistData } = useChecklistContext();

  const { t } = useTranslation();
  const damageLabels = [
    t("Tire"),
    t("Engine"),
    t("BodyAndPaint"),
    t("Windows"),
    t("AC"),
    t("Interior"),
    t("Wheels"),
    t("GearShift"),
    t("Mechanics"),
    t("SpareWheel"),
  ];

  const deliveryDocumentsLabels = [
    { label: t("DUT/ATPVe"), optional: false },
    { label: t("Manual"), optional: false },
    { label: t("Key/Alarm"), optional: false },
    { label: t("TermOfTickets"), optional: false },
    { label: t("IPVA"), optional: false },
    { label: t("CRLVe"), optional: false },
    { label: t("CNH"), optional: false },
    { label: t("ThirdPartyStatement"), optional: true },
    { label: t("DischargeAuth"), optional: true },
  ];

  const armouringLabels = [
    { label: t("RegularizedDocument"), checked: true },
    { label: t("InvoicesFromTheArmourer"), checked: true },
    { label: t("StatementOfResponsibilityTheArmourer"), checked: true },
    { label: t("ArmyDeclaration"), checked: true },
    { label: t("NonRegularizedVehicles"), checked: true },
  ];

  const [armouringOptions, setArmouringOptions] = useState(() => {
    const auxList = [];
    for (let index = 0; index < armouringLabels.length; index++) {
      auxList.push({
        label: armouringLabels[index].label,
        checked: armouringLabels[index].checked,
      });
    }
    return auxList;
  });

  const handleCheckboxChange = (index: number) => {
    setArmouringOptions(prevOptions =>
      prevOptions.map((item, i) => (i === index ? { ...item, checked: !item.checked } : item)),
    );
  };

  const [damagesList, setDamagesList] = useState<DamageListsTypes>(() => {
    const auxList = [];
    for (let index = 1; index <= damageLabels.length; index++) {
      auxList.push({
        title: index,
        withDamage: false,
        cost: null,
        description: null,
      });
    }
    return auxList;
  });

  const [deliveryDocumentsList, setDeliveryDocumentsList] = useState<DeliveryDocumentsTypes>(
    () => {
      const auxList = [];
      for (let index = 1; index <= deliveryDocumentsLabels.length; index++) {
        auxList.push({
          item: index,
          checked: false,
        });
      }
      return auxList;
    },
  );

  const [selectedColor, setSelectedColor] = useState<{
    name: string;
    price: number;
  }>({ name: "", price: 0 });

  const [kbbRequest, setKbbRequest] = useState<VehicleEquipment[]>([]);

  const handleChange =
    (accordionOption: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? accordionOption : false);
    };

  const handleCheckedOption = (value: boolean, equipmentId: number) => {
    const updatedList = equipmentsList.map(equip => {
      if (equip.equipmentId === equipmentId) {
        return {
          ...equip,
          checked: value,
          equipmentSettled: value,
        };
      }
      return equip;
    });
    setEquipmentsList(updatedList);
  };

  function handleDamageButtonClick(title: number, isDamaged: boolean) {
    setDamagesList(prev =>
      prev.map(damage => {
        if (damage.title === title) {
          return {
            title,
            withDamage: isDamaged,
            cost: null,
            description: "",
          };
        }
        return damage;
      }),
    );
  }

  useEffect(() => {
    setTotalDamages(damagesList.reduce((acc, { cost }) => acc + (cost ?? 0), 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [damagesList]);

  useEffect(() => {
    const cost =
      equipmentsList
        .filter(equip => equip.checked === true)
        .reduce((acc, { equipmentPrice }) => acc + (equipmentPrice ?? 0), 0) +
      selectedColor.price;
    setEquipmentsCost(cost);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentsList, selectedColor]);

  function handleInputChange(title: number, cost: number) {
    setDamagesList(prev =>
      prev.map(damage => {
        if (damage.title === title) {
          return {
            ...damage,
            cost,
          };
        }
        return damage;
      }),
    );
  }

  function handleDamageError(withDamage: boolean, cost: number | null, title: number) {
    if (withDamage) {
      if (cost === null || cost === 0) {
        if (!damageErrors.includes(title)) {
          setDamageErrors(prevErrors => [...prevErrors, title]);
        }
      } else {
        setDamageErrors(prevErrors => prevErrors.filter(errorTitle => errorTitle !== title));
      }
    } else {
      setDamageErrors(prevErrors => prevErrors.filter(errorTitle => errorTitle !== title));
    }
  }

  useEffect(() => {
    damagesList.forEach(({ title, withDamage, cost }) => {
      handleDamageError(withDamage, cost, title);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [damagesList]);

  function handleTextAreaChange(title: number, description: string) {
    setDamagesList(prev =>
      prev.map(damage => {
        if (damage.title === title) {
          return {
            ...damage,
            description,
          };
        }
        return damage;
      }),
    );
  }

  function handleDeliveryCheck(item: number, isChecked: boolean | null) {
    setDeliveryDocumentsList(prev =>
      prev.map(delivery => {
        if (delivery.item === item) {
          return {
            item,
            checked: isChecked,
          };
        }
        return delivery;
      }),
    );
  }
  const handleActiveSwitch = () => {
    setActiveSwitch(prevState => !prevState);
    setEquipmentsList(prev =>
      prev.map(equip => {
        if (equip.equipmentId === 1000000) {
          return {
            ...equip,
            checked: !equip.checked,
            equipmentSettled: !equip.checked,
          };
        }
        return equip;
      }),
    );
  };

  async function handleKbbRequest() {
    try {
      const kbbResponse = await GetAllEquipments(
        checklistData?.tradeInVehicleData.kbbId,
        checklistData?.tradeInVehicleData.modelYear,
      );
      setKbbRequest(kbbResponse);
    } catch (err) {
      console.log(err);
      setError(true);
    }
  }

  const handleEquipmentsColors = () => {
    if (kbbRequest.length > 0) {
      const mappedEquipsCar = kbbRequest.flatMap(equip =>
        equip.subCategory.flatMap(e =>
          e.equipments.filter(equip => equip.flagOptionID === 999999),
        ),
      );

      setColors(
        mappedEquipsCar.map(equip => ({
          colorId: equip.equipmentID,
          colorName: equip.name,
          colorPrice: equip.price,
          colorSettled: false,
        })),
      );
    }
  };

  const handleEquipmentsOptional = () => {
    if (kbbRequest.length > 0) {
      setEquipmentsList([]);
      const mappedEquipsCar = kbbRequest.flatMap(equip =>
        equip.subCategory.flatMap(e =>
          e.equipments.filter(
            equip => equip.flagOptionID !== 999999 && equip.flagOptionID !== 0,
          ),
        ),
      );

      setEquipmentsList(
        mappedEquipsCar.map(equip => ({
          equipmentId: equip.equipmentID,
          checked: false,
          equipmentName: equip.name,
          equipmentPrice: equip.price,
          equipmentSettled: false,
        })),
      );
    }
  };

  useEffect(() => {
    if ((colors.length === 0 || equipments.length === 0) && kbbRequest.length === 0) {
      handleKbbRequest();
    }
    if (colors.length === 0) {
      handleEquipmentsColors();
    }

    if (equipments.length === 0) {
      handleEquipmentsOptional();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colors, equipments, kbbRequest]);

  const resetState = useCallback(() => {
    if (checklistData?.checklist === null) {
      const auxListDamageLabels = [];
      for (let index = 1; index <= damageLabels.length; index++) {
        auxListDamageLabels.push({
          title: index,
          withDamage: false,
          cost: null,
          description: null,
        });
      }

      const auxListDeliveryDocumentsLabels = [];
      for (let index = 1; index <= deliveryDocumentsLabels.length; index++) {
        auxListDeliveryDocumentsLabels.push({
          item: index,
          checked: false,
        });
      }

      setDamagesList(auxListDamageLabels);
      setDeliveryDocumentsList(auxListDeliveryDocumentsLabels);
    }
  }, [checklistData?.checklist, damageLabels?.length, deliveryDocumentsLabels?.length]);

  useEffect(() => {
    resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklistData]);

  useEffect(() => {
    if (checklistData && checklistData.checklist && checklistData.checklist?.damages) {
      setDamagesList(checklistData.checklist?.damages);
    }
    if (
      checklistData &&
      checklistData.checklist &&
      checklistData.checklist?.deliveryDocuments
    ) {
      const deliveryDocuments = checklistData.checklist?.deliveryDocuments;

      if (deliveryDocuments.length > 9) {
        setDeliveryDocumentsList(deliveryDocuments.slice(0, 9));
        setArmouringOptions(
          deliveryDocuments.slice(9, 14).map((item: any, index: number) => ({
            label: armouringLabels[index].label,
            checked: item.checked,
          })),
        );
      } else {
        setDeliveryDocumentsList(deliveryDocuments);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklistData]);

  useEffect(() => {
    if (saveChecklistData) {
      const updatedDeliveryDocumentsList = [...deliveryDocumentsList];

      if (activeSwitch) {
        armouringOptions.forEach((option, index) => {
          updatedDeliveryDocumentsList.push({
            item: deliveryDocumentsList.length + index + 1,
            checked: option.checked,
          });
        });
      }

      if (colors.every(item => item.colorSettled === false)) {
        setColorsEmptyErrors(true);
      } else {
        setColorsEmptyErrors(false);
      }

      const payload = {
        colors,
        damages: damagesList,
        deliveryDocuments: updatedDeliveryDocumentsList,
        equipments: equipmentsList,
      };

      if (payload) {
        setPostChecklistData(payload);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    saveChecklistData,
    activeSwitch,
    selectedColor,
    damagesList,
    deliveryDocumentsList,
    equipmentsList,
    armouringOptions,
  ]);

  useEffect(() => {
    const colorSettled = colors.find(color => color.colorSettled);
    setSelectedColor(
      colorSettled
        ? { name: colorSettled.colorName, price: colorSettled.colorPrice }
        : { name: "", price: 0 },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colors]);

  useEffect(() => {
    setEquipmentsList(
      equipments.map(equip => ({
        ...equip,
        checked: equip.equipmentSettled,
      })),
    );

    setActiveSwitch(
      equipments.some(
        ({ equipmentId, equipmentSettled }) => equipmentId === 1000000 && equipmentSettled,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipments]);

  function handleColorChange(colorName: string, colorPrice: number) {
    setSelectedColor({
      name: colorName,
      price: colorPrice,
    });

    const updatedColors = colors.map(item =>
      item.colorName === colorName
        ? { ...item, colorSettled: true }
        : { ...item, colorSettled: false },
    );

    setColors(updatedColors);
    setColorsEmptyErrors(false);
  }

  return (
    <MainAccording>
      <Wrapper>
        <Title>{t("VehicleChecklist")}</Title>

        {/* //# Cores */}
        <Accordion
          expanded={expanded === "accordionOption1" || colorsEmptyErrors}
          onChange={handleChange("accordionOption1")}
          sx={{
            boxShadow: "none",
            border: "1px solid #E1E1E2",
            borderRadius: "6px",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#FFFFFF" }} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{
              backgroundColor: colorsEmptyErrors ? "#C4390A" : "#1F3E74",
              color: "#FFFFFF",
              borderTopRightRadius: 5,
              borderTopLeftRadius: 5,
              "&.Mui-focusVisible": {
                backgroundColor: "#1F3E74",
              },
            }}
          >
            <Typography>{t("Colors")}</Typography>
          </AccordionSummary>

          <div>
            {error ? (
              <BoxError>
                <span>{t("ErrorColors")}</span>
                <RetryEquipments onClick={handleEquipmentsColors}>
                  <CoxIcon
                    name={"reload"}
                    width={12}
                    height={12}
                    fillColor="#007bce"
                  ></CoxIcon>
                  {t("Retry").toUpperCase()}
                </RetryEquipments>
              </BoxError>
            ) : (
              <>
                <SubTitleAccording
                  style={{ color: colorsEmptyErrors ? "#C4390A" : "#4a4a4a" }}
                >
                  {t("SelectedColor")}
                </SubTitleAccording>
                <InformationAccording>
                  <FormControl
                    sx={{
                      "&.MuiFormControl-root": {
                        width: "200%",
                      },
                    }}
                  >
                    {colors.map(({ colorName, colorId, colorPrice }, idx) => (
                      <div key={colorId}>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue={selectedColor}
                          name="radio-buttons-group"
                          sx={{
                            borderColor: "#e1e1e2",
                            "&.MuiFormGroup-root": {
                              padding: "4px 8px",
                            },
                          }}
                          onChange={() => handleColorChange(colorName, colorPrice)}
                        >
                          <FormControlLabel
                            value={selectedColor}
                            control={
                              <Radio
                                sx={{
                                  "&.Mui-checked": {
                                    color: "#1f3e74c6",
                                  },
                                }}
                                size="small"
                              />
                            }
                            label={<CustomLabel>{colorName}</CustomLabel>}
                            checked={colorName === selectedColor.name}
                          />
                        </RadioGroup>
                        {idx < colors.length - 1 && <hr />}
                      </div>
                    ))}
                  </FormControl>
                </InformationAccording>
              </>
            )}
          </div>
        </Accordion>

        {/* //# Opcionais */}
        <Accordion
          expanded={expanded === "accordionOption2"}
          onChange={handleChange("accordionOption2")}
          sx={{
            boxShadow: "none",
            border: "1px solid #E1E1E2",
            borderRadius: "6px",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#FFFFFF" }} />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
            sx={{
              backgroundColor: "#1F3E74",
              color: "#FFFFFF",
              borderTopRightRadius: 5,
              borderTopLeftRadius: 5,
              "&.Mui-focusVisible": {
                backgroundColor: "#1F3E74",
              },
            }}
          >
            <Typography>{t("Equipments")}</Typography>
          </AccordionSummary>
          {error ? (
            <BoxError>
              <span>{t("ErrorEquipments")}</span>
              <RetryEquipments onClick={handleEquipmentsOptional}>
                <CoxIcon name={"reload"} width={12} height={12} fillColor="#007bce"></CoxIcon>
                {t("Retry").toUpperCase()}
              </RetryEquipments>
            </BoxError>
          ) : (
            <>
              {" "}
              {equipmentsList
                ?.filter(equip => equip.equipmentId !== 1000000)
                .map(({ equipmentName, equipmentId, checked }) => (
                  <div key={equipmentId}>
                    <TitleOptional>{equipmentName}</TitleOptional>
                    <InformationAccording padding={6}>
                      <Checkbox
                        checked={checked}
                        onChange={({ target }) =>
                          handleCheckedOption(target.checked, equipmentId)
                        }
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{
                          borderColor: "#e1e1e2",
                          "&.Mui-checked": {
                            color: "#1f3e74c6",
                          },
                        }}
                      />
                      <TypographySecond>{checked ? t("Selected") : ""}</TypographySecond>
                    </InformationAccording>
                  </div>
                ))}
              <div>
                <BoxSwitch
                  className={
                    equipmentsList.every(equip => equip.equipmentId !== 1000000)
                      ? "disabled"
                      : ""
                  }
                >
                  <Switch
                    checked={activeSwitch}
                    onChange={handleActiveSwitch}
                    sx={{
                      "& .MuiSwitch-switchBase.Mui-checked": {
                        color: "#1f3e74",
                      },
                    }}
                    disabled={equipmentsList.every(equip => equip.equipmentId !== 1000000)}
                  />
                  <ArmouringTitle>{t("Armouring")}</ArmouringTitle>
                </BoxSwitch>
                {activeSwitch && (
                  <ArmouringItems>
                    {armouringOptions.map((item, index) => (
                      <section key={index}>
                        <ArmouringItem>
                          <Checkbox
                            sx={{
                              borderColor: "#e1e1e2",
                              "&.Mui-checked": {
                                color: "#1f3e74",
                              },
                            }}
                            checked={item.checked}
                            onChange={() => handleCheckboxChange(index)}
                          />
                          <TypographySecond>{item.label}</TypographySecond>
                        </ArmouringItem>
                      </section>
                    ))}
                  </ArmouringItems>
                )}
              </div>
            </>
          )}
        </Accordion>

        {/* //# Avaliação */}
        <Accordion
          expanded={expanded === "accordionOption3"}
          onChange={handleChange("accordionOption3")}
          sx={{
            boxShadow: "none",
            border: "1px solid #E1E1E2",
            borderRadius: "6px",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#FFFFFF" }} />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
            sx={{
              backgroundColor: "#1F3E74",
              color: "#FFFFFF",
              borderTopRightRadius: 5,
              borderTopLeftRadius: 5,
              "&.Mui-focusVisible": {
                backgroundColor: "#1F3E74",
              },
            }}
          >
            <Typography>{t("Evaluation")}</Typography>
          </AccordionSummary>
          <div>
            <SubTitleAccording>
              <span>{t("Item")}</span>
              <span>{t("DiscountedValue")}</span>
            </SubTitleAccording>
            {damagesList.map(({ title, withDamage, cost, description }) => (
              <div key={title}>
                <InformationAccording
                  style={{ backgroundColor: "#FFFFFF" }}
                  justify="space-between"
                  padding={16}
                >
                  <DamageTitle>{damageLabels[title - 1]}</DamageTitle>
                  <ActionsContainer>
                    <DamageButton
                      active={!withDamage}
                      onClick={() => handleDamageButtonClick(title, false)}
                      profile="manheim"
                    >
                      {t("Good")}
                    </DamageButton>
                    <DamageButton
                      active={withDamage}
                      onClick={() => handleDamageButtonClick(title, true)}
                      profile="manheim"
                    >
                      {t("WithDamage")}
                    </DamageButton>
                    <InputAtom
                      disabled={!withDamage}
                      maxLength={12}
                      placeholder={t("TypeHere")}
                      value={cost ?? ""}
                      onFocus={() => handleDamageError(withDamage, cost, title)}
                      onChange={value => {
                        handleInputChange(title, Number(value));
                        handleDamageError(withDamage, cost, title);
                      }}
                      maskType="currency"
                      hasError={withDamage && !cost}
                      errorMessage={t("RequiredField")}
                    />
                  </ActionsContainer>
                </InformationAccording>
                <Show when={withDamage} fallback={null}>
                  <DescriptionContainer>
                    <label htmlFor="description">{t("DescribeProblem")}</label>
                    <textarea
                      placeholder={t("TypeHere")}
                      id="description"
                      maxLength={1000}
                      value={description ?? ""}
                      onChange={({ target }) => handleTextAreaChange(title, target.value)}
                    />
                  </DescriptionContainer>
                </Show>
              </div>
            ))}
          </div>
        </Accordion>

        {/* //# Relação para entrega */}
        <Accordion
          expanded={expanded === "accordionOption4"}
          onChange={handleChange("accordionOption4")}
          sx={{
            boxShadow: "none",
            border: "1px solid #E1E1E2",
            borderRadius: "6px",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#FFFFFF" }} />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
            sx={{
              backgroundColor: "#1F3E74",
              color: "#FFFFFF",
              borderTopRightRadius: 5,
              borderTopLeftRadius: 5,
              "&.Mui-focusVisible": {
                backgroundColor: "#1F3E74",
              },
            }}
          >
            <Typography>{t("Delivery")}</Typography>
          </AccordionSummary>
          {deliveryDocumentsList.map(({ item, checked }) => (
            <InformationAccording padding={6} border key={item} justify="space-between">
              <div>
                <Checkbox
                  checked={!!checked}
                  onChange={({ target }) => handleDeliveryCheck(item, target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                  sx={{
                    borderColor: "#e1e1e2",
                    "&.Mui-checked": {
                      color: "#1f3e74c6",
                    },
                  }}
                />
                <TypographySecond>{deliveryDocumentsLabels[item - 1].label}</TypographySecond>
              </div>
              {deliveryDocumentsLabels[item - 1].optional && (
                <div style={{ paddingRight: 16 }}>
                  <Checkbox
                    checked={checked === null}
                    onChange={({ target }) =>
                      handleDeliveryCheck(item, target.checked ? null : false)
                    }
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{
                      borderColor: "#e1e1e2",
                      "&.Mui-checked": {
                        color: "#1f3e74",
                      },
                    }}
                  />
                  <TypographySecond>{t("DoNotApply")}</TypographySecond>
                </div>
              )}
            </InformationAccording>
          ))}
        </Accordion>
      </Wrapper>
    </MainAccording>
  );
};
