import React, { createContext, useState } from "react";

import { ITradeinProviderState } from "../hooks/contexts/use-tradein-context.hook";
import { IGetPaginatedTradeInResponse, IUserAllInformation } from "../models";

export const TradeInContext = createContext({} as any);

export const TradeinProvider = (props: any) => {
  const [userContext, setUserContext] = useState<IUserAllInformation>();
  const [dataTradeIn, setDataTradeIn] = useState<IGetPaginatedTradeInResponse[]>([]);

  return (
    <TradeInContext.Provider
      value={
        {
          userContext,
          setUserContext,
          dataTradeIn,
          setDataTradeIn,
        } as ITradeinProviderState
      }
    >
      {props.children}
    </TradeInContext.Provider>
  );
};
