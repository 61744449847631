/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { useProductsTradein } from "../hooks";
import { IChecklistProviderState } from "../hooks/contexts/use-checklist-context.hook";
import { IGetDebtsResponse, IProposalDetailsResponse } from "../models";

export const ChecklistContext = createContext({} as any);

export const ChecklistProvider: React.FC = ({ children }) => {
  const [checklistData, setChecklistData] = useState<IProposalDetailsResponse>();
  const [statusProposal, setStatusProposal] = useState(true);
  const [equipmentsCost, setEquipmentsCost] = useState<number | undefined>();
  const [renavamLoading, setRenavamLoading] = useState(false);
  const [saveChecklistData, setSaveChecklistData] = useState(false);
  const [hasProblemConsultingDebts, setHasProblemConsultingDebts] = useState<boolean | null>(
    null,
  );
  const [debts, setDebts] = useState<IGetDebtsResponse[]>([]);
  const [renavamInput, setRenavamInput] = useState("");
  const { getDebts } = useProductsTradein();

  const { tradeInId } = useParams<{ tradeInId: string }>();

  function cleanState() {
    setHasProblemConsultingDebts(null);
    setStatusProposal(true);
    setEquipmentsCost(undefined);
    setChecklistData(undefined);
    setSaveChecklistData(false);
    setRenavamLoading(false);
    setDebts([]);
    setRenavamInput("");
  }

  async function searchDebts(renavam: string) {
    try {
      setRenavamLoading(true);
      const data = await getDebts({ renavam, tradeInId: Number(tradeInId) });
      setDebts(data);
    } catch (error) {
      console.error("Error", error);
      setHasProblemConsultingDebts(true);
      setDebts([]);
      throw error;
    } finally {
      setRenavamLoading(false);
    }
  }

  const value = useMemo(
    () =>
      ({
        hasProblemConsultingDebts,
        setHasProblemConsultingDebts: (status: boolean) =>
          setHasProblemConsultingDebts(status),
        statusProposal,
        setStatusProposal: (status: boolean) => setStatusProposal(status),
        checklistData,
        setChecklistData,
        equipmentsCost,
        setEquipmentsCost,
        saveChecklistData,
        setSaveChecklistData,
        renavamLoading,
        setRenavamLoading,
        tradeInId: Number(tradeInId),
        cleanState,
        debts,
        setDebts,
        searchDebts,
        renavamInput,
        setRenavamInput: (renavam: string) => setRenavamInput(renavam),
      }) as IChecklistProviderState,
    [
      hasProblemConsultingDebts,
      statusProposal,
      checklistData,
      equipmentsCost,
      saveChecklistData,
      renavamLoading,
      tradeInId,
      debts,
      setDebts,
      renavamInput,
    ],
  );

  return <ChecklistContext.Provider value={value}>{children}</ChecklistContext.Provider>;
};
