import { AccordionSummary } from "@mui/material";
import { ButtonSecondary } from "c4u-web-components";
import styled, { css } from "styled-components";

export const MainAccording = styled.div`
  width: 100%;
  padding: 0px 20px 25px 20px;
`;

type InformationAccordingProps = {
  readonly justify?: "flex-start" | "space-between";
  readonly padding?: number;
  readonly border?: boolean;
};

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  background-color: rgb(225, 225, 226, 25%);
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 25px 82px;
  border-radius: 4px;
  border: 1px solid #dee2e5;
`;

export const Title = styled.span`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #1f3e74;
`;

export const TitleAccording = styled(AccordionSummary)`
  background-color: #1f3e74;
  color: #000;
`;

export const SubTitleAccording = styled.div`
  background-color: #e1e1e2;
  padding: 8px 16px 8px;
  font-family: ${(props) => props.theme.fontFamily.Helvetica};
  font-weight: 700;
  font-size: 14px;
  color: #4a4a4a;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleOptional = styled.div`
  background-color: #e1e1e2;
  padding: 16px;
  font-family: ${(props) => props.theme.fontFamily.Helvetica};
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #4a4a4a;
`;

export const Typography = styled.span`
  font-family: ${(props) => props.theme.fontFamily.Helvetica};
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #f9f9f9;
`;

export const TypographySecond = styled.span`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #4a4a4a;
`;

export const BoxError = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px 0px;

  span {
    font-family: ${(props) => props.theme.fontFamily.Montserrat};
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #4a4a4a;
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 0px 16px 16px;
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;

  label {
    color: #808285;
    margin: 0;
  }

  textarea {
    color: #4a4a4a;
    resize: none;
    outline: none;
    border: 1px solid #dee2e5;
    border-radius: 4px;
    height: 66px;
    transition: border-color 0.2s ease-in;
    padding: 12px 16px;

    &:focus {
      border-color: ${({ theme }) => theme.colors.BlueNavy};
    }
  }
`;

export const InformationAccording = styled.div<InformationAccordingProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify ?? "flex-start"};
  gap: 10px;
  background-color: #f9f9f9;
  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding}px;
    `};

  ${({ border }) =>
    border &&
    css`
      & + & {
        border-top: 1px solid #dee2e5;
      }
    `}

  hr {
    width: 100%;
    color: #dee2e5;
    border: 0;
    border-top-width: 0px;
    border-top-style: none;
    border-top-color: currentcolor;
    border-top: 1px solid #0000001a;
    margin-bottom: 0;
    margin-top: 0;
  }

  label {
    margin: 0;
  }
`;

export const BoxSwitch = styled.div`
  background-color: #e1e1e2;
  padding: 8px 4px;
  
   &.disabled {
    cursor: not-allowed;
  }
`;

export const ArmouringTitle = styled.span`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-weight: 700;
  font-size: 13px;
  line-height: 22px;
`;

export const ArmouringItems = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 16px;
  align-items: center;
  justify-items: flex-start;
  place-items: center flex-start;
  padding: 10px 10px;
`;

export const ArmouringItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

interface DamageButtonProps {
  readonly active: boolean;
}

export const DamageButton = styled(ButtonSecondary)<DamageButtonProps>`
  width: 88px;
  height: 24px;
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-weight: 700;
  font-size: 12px;
  text-transform: capitalize;
  padding: 2px;

  :focus {
    text-decoration: none;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: #1f3e74;
      color: white;
    `}
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const CustomLabel = styled.span`
  font-family: ${(props) => props.theme.fontFamily.Helvetica};
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #4a4a4a;
`;

export const DamageTitle = styled.span`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #4a4a4a;
`;

export const RetryEquipments = styled.a`
  color: #007bce;

  display: flex;
  align-items: center;
  gap: 2px;

  text-decoration: underline;
  display: flex;
  font-size: 16px;
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  font-size: 12px;

  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
