import { PlayArrow } from "@mui/icons-material";
import React from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation, Virtual } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { VideoFormat } from "../../../helper";
import { CarIconList } from "../../assets";

import {
  ContainerCarousel,
  ContainerIcon,
  IconFrame,
  ImageCar,
  ImageIcon,
  VideoContainer,
} from "./carousel-of-car-photos.atom.style";

interface IProps {
  photos: Array<{ imageId: number; url: string }>;
  resetPhotos: () => void;
}

export const CarouselOfCarPhotosAtom: React.FC<IProps> = ({ photos, resetPhotos }) => {
  return (
    <ContainerCarousel>
      <Swiper
        modules={[Navigation, Virtual]}
        slidesPerView={1}
        navigation
        virtual={{
          enabled: true,
          cache: true,
        }}
        style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }}
      >
        {!photos.length ? (
          <ContainerIcon>
            <ImageIcon src={CarIconList} alt="" />
          </ContainerIcon>
        ) : (
          photos.map((car, idx) => {
            const isVideo = VideoFormat(car.url);
            return (
              <SwiperSlide key={car.imageId} color="#fff" virtualIndex={idx}>
                {isVideo ? (
                  <VideoContainer>
                    <IconFrame>
                      <PlayArrow htmlColor="#fff" fontSize="large" />
                    </IconFrame>
                    <video
                      width={487}
                      height={275}
                      style={{ objectFit: "cover" }}
                      onErrorCapture={resetPhotos}
                    >
                      <source src={car.url} type="video/webm"></source>
                    </video>
                  </VideoContainer>
                ) : (
                  <ImageCar onErrorCapture={resetPhotos} src={car.url} alt="" />
                )}
              </SwiperSlide>
            );
          })
        )}
      </Swiper>
    </ContainerCarousel>
  );
};
