import { CoxIcon } from "c4u-web-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useRedirectWhatsApp } from "../../../hooks";
import { ICustomer } from "../../../models";
import { ButtonWhatsApp } from "./whatsapp-button.atom.style";

interface VehicleProps {
	brandName?: string;
	modelName?: string;
	versionName?: string;
    modelYear?: number;
}
interface IProps {
  dealerName: string;
  vehicle: VehicleProps;
  customer: ICustomer;
}

export const WhatsAppButton = ({ dealerName, vehicle, customer }: IProps) => {
  const { redirect } = useRedirectWhatsApp();
  const { t } = useTranslation();
	const message = `Olá, sou da ${dealerName}. Verificamos que você possui interesse de realizar uma compra de um veículo ${vehicle.brandName} ${vehicle.modelName} ${vehicle.modelYear} ${vehicle.versionName}. Podemos falar?`

	return (
		<ButtonWhatsApp onClick={() => redirect(customer.phone, message)}>
			<CoxIcon name={"whatsapp"} width={12} height={12} />
			{t("StartConversation")}
		</ButtonWhatsApp>
	)
}
