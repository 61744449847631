import { NumberHelper } from "c4u-web-components";
import React from "react";
import { useTranslation } from "react-i18next";

import { IGetTradeInPricesResponseModel } from "../../../models";
import { LogoKbb } from "../../assets";

import {
  FinalAcquisitionPriceContainer,
  Price,
  PriceInformation,
  Title,
  TradeInPrices,
  TradeInPricesContainer,
} from "./price-information.molecule.style";

type PriceInformationMoleculeTypes = {
  tradeInPrices: IGetTradeInPricesResponseModel | null;
  negotiatedValueChecklist: number | null;
};

export const PriceInformationMolecule = ({
  tradeInPrices,
  negotiatedValueChecklist,
}: PriceInformationMoleculeTypes) => {
  const { t } = useTranslation();

  return (
    <PriceInformation>
      <FinalAcquisitionPriceContainer>
        <Title>{t("FinalAcquisitionPrice")}</Title>
        <Price>{NumberHelper.toFormatCurrency(negotiatedValueChecklist)}</Price>
      </FinalAcquisitionPriceContainer>
      <TradeInPricesContainer>
        <TradeInPrices>
          <img src={LogoKbb} alt="kbb-logo" />
          <FinalAcquisitionPriceContainer>
            <Title>{t("TradeInPrice")}</Title>
            <Price>
              {`R$ ${NumberHelper.toFormatString(
                tradeInPrices ? tradeInPrices.pricesUpdated.priceLow : 0,
                0,
                "--",
              )} - R$ ${NumberHelper.toFormatString(
                tradeInPrices ? tradeInPrices?.pricesUpdated.priceHigh : 0,
                0,
                "--",
              )}`}
            </Price>
          </FinalAcquisitionPriceContainer>
        </TradeInPrices>

        <p>{tradeInPrices?.pricesUpdated.requestedAt}</p>
      </TradeInPricesContainer>
    </PriceInformation>
  );
};
