import React, { useContext } from "react";

import { TradeInContext } from "../../contexts";
import { IGetPaginatedTradeInResponse, IUserAllInformation } from "../../models";

export interface ITradeinProviderState {
  setUserContext: React.Dispatch<IUserAllInformation>;
  userContext: IUserAllInformation;
  setDataTradeIn: React.Dispatch<IGetPaginatedTradeInResponse[]>;
  dataTradeIn: IGetPaginatedTradeInResponse[];
}

export const useTradeinContext = () => {
  const context = useContext<ITradeinProviderState>(TradeInContext);

  if (!context) {
    throw new Error("Empty context");
  }

  return context;
};
