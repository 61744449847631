import React from "react";

import { ChecklistProvider } from "../../../contexts";
import { ChecklistOrganisms } from "../../organisms";

export const ChecklistPage: React.FC = () => {
  return (
    <ChecklistProvider>
      <ChecklistOrganisms />
    </ChecklistProvider>
  );
};
