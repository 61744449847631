import React from "react";
import { Spinner } from "react-bootstrap";

import { LoadingContainer } from "./loading-spinner.atom.styles";

export const LoadingSpinner = () => {
  return (
    <LoadingContainer>
      <Spinner animation="border" />
    </LoadingContainer>
  );
};
