import { FloatLabelInputOrganism } from "@dtbr-generic/components";
import { NumberHelper, CoxIcon } from "c4u-web-components";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { VehicleDebtCard } from "..";
import { formatDate, formatCellPhoneNumber } from "../../../helper";
import { useChecklistContext } from "../../../hooks";
import { IProposalDetailsResponse, PaymentMethodEnum } from "../../../models";
import { CarIconList, Calendar } from "../../assets";
import { ChecklistCarousel, WhatsAppButton } from "../../atoms";

import {
  CardContainer,
  MainImage,
  Details,
  VehicleInformation,
  Title,
  Year,
  PlateAndPrice,
  InputContainer,
  Description,
  Plate,
  PlateValue,
  PriceValue,
  Price,
  Customer,
  CustomerInfo,
  WhatsAppContainer,
  CustomerName,
  CustomerEmail,
  CustomerPhone,
  VehicleDebts,
  Decision,
  DecisionTitle,
  Renavam,
  RenavamValue,
  DecisionImage,
  DecisionDetails,
  DecisionName,
  DecisionPrice,
  LabelsPrice,
  DecisionValue,
  FinancedValue,
  AddedDate,
  IconCalendar,
  StyledP,
  ErrorMessage,
  DecisionDescription,
  DecisionVehicle,
  ProposalIdentifier,
  BoxInformationVehicle,
  InformationVehicle,
  DataAboutProposal,
} from "./vehicle-checklist-card.style";

interface ICarCardProps {
  tradeInData: IProposalDetailsResponse;
  dealershipName: string;
  renavamErrors: string;
  setRenavamErrors: Dispatch<SetStateAction<string>>;
  isModalVisible: boolean;
  proposalId: number;
}

const formatVehicle = (brandName: string, model: string, modelYear: number) => {
  return `${brandName} ${model}  ${modelYear}`;
};

export const VehicleChecklistCarCardMolecule: React.FC<ICarCardProps> = ({
  tradeInData,
  dealershipName,
  renavamErrors,
  setRenavamErrors,
  isModalVisible,
  proposalId,
}) => {
  const { t } = useTranslation();
  const [showSavedRenavam, setShowSavedRenavam] = useState(false);
  const { renavamInput, setRenavamInput, hasProblemConsultingDebts } = useChecklistContext();

  useEffect(() => {
    if (tradeInData && tradeInData.tradeInVehicleData.renavam) {
      setRenavamInput(tradeInData.tradeInVehicleData.renavam);
      setShowSavedRenavam(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradeInData]);

  useEffect(() => {
    if (isModalVisible && renavamInput.length === 11 && !hasProblemConsultingDebts) {
      setShowSavedRenavam(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible, renavamInput]);

  const validateRenavam = () => {
    if (renavamInput.length === 0) {
      setRenavamErrors("");
    } else if (renavamInput.length < 11) {
      setRenavamErrors("RENAVAM inválido");
    } else {
      setRenavamErrors("");
    }
  };
  const handleRenavamChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRenavamInput(event.target.value);
    if (event.target.value.length >= 11) {
      setRenavamErrors("");
    }
  };

  return (
    <>
      <CardContainer>
        <MainImage>
          <ChecklistCarousel
            brandName={tradeInData.tradeInVehicleData.brand}
            modelName={tradeInData.tradeInVehicleData.model}
            versionName={tradeInData.tradeInVehicleData.version}
            hp={tradeInData.tradeInVehicleData.hp}
            modelYear={tradeInData.tradeInVehicleData.modelYear}
          />
        </MainImage>
        <Details>
          <VehicleInformation>
            <BoxInformationVehicle>
              <InformationVehicle>
                <Title>
                  {`${tradeInData.tradeInVehicleData.brand}
                ${tradeInData.tradeInVehicleData.model} `}
                  <Year>
                    {t("Year").toUpperCase()}
                    {tradeInData.tradeInVehicleData.modelYear}
                  </Year>
                </Title>
                <Description>{`${tradeInData.tradeInVehicleData.version} ${tradeInData.tradeInVehicleData.hp} CV`}</Description>
              </InformationVehicle>

              <DataAboutProposal>
                <ProposalIdentifier>
                  <span>
                    <b>{t("ProposalId")}</b> {proposalId}
                  </span>
                </ProposalIdentifier>
                <AddedDate>
                  <IconCalendar src={Calendar}></IconCalendar>
                  {t("AddedIn")}
                  <strong>{formatDate(tradeInData.addedIn)}</strong>
                </AddedDate>
              </DataAboutProposal>
            </BoxInformationVehicle>
            <PlateAndPrice>
              <Plate>
                {t("Plate")}
                <PlateValue>{tradeInData.tradeInVehicleData.plate}</PlateValue>
              </Plate>
              <Price>
                {t("AmountRequested")}
                <PriceValue>
                  {`R$ ${NumberHelper.toFormatString(
                    tradeInData.tradeInVehicleData?.requestedPrice,
                    0,
                    "--",
                  )}`}
                </PriceValue>
              </Price>
              <InputContainer>
                {showSavedRenavam &&
                !tradeInData.tradeInVehicleData.hasProblemConsultingDebts ? (
                  <Renavam>
                    RENAVAM
                    <RenavamValue>{renavamInput}</RenavamValue>
                  </Renavam>
                ) : (
                  <FloatLabelInputOrganism
                    labelText="RENAVAM"
                    placeholder="RENAVAM"
                    type="text"
                    maskType="onlyNumerics"
                    maxLength={11}
                    onChange={handleRenavamChange}
                    onBlur={validateRenavam}
                    style={{ height: "50px", fontSize: "12px" }}
                    value={renavamInput}
                  />
                )}
                {renavamErrors ? <ErrorMessage>{renavamErrors}</ErrorMessage> : null}
              </InputContainer>
            </PlateAndPrice>
          </VehicleInformation>
          {hasProblemConsultingDebts === null && renavamInput.length < 11 ? (
            <VehicleDebts>
              Digite o <strong style={{ margin: "0 2px" }}>RENAVAM</strong> para consultar os
              débitos.
            </VehicleDebts>
          ) : (
            <VehicleDebtCard
              hasProblemsConsultingDebts={
                tradeInData.tradeInVehicleData.hasProblemConsultingDebts
              }
              requestedPrice={tradeInData.tradeInVehicleData.requestedPrice}
              renavam={renavamInput}
              vehicleDebts={tradeInData.tradeInVehicleData.vehicleDebts}
            />
          )}
          <Decision>
            <DecisionTitle>{t("BuyingDecision").toUpperCase()}</DecisionTitle>
            <div style={{ display: "flex", alignItems: "center", margin: "0" }}>
              <DecisionDetails>
                <DecisionImage
                  src={
                    tradeInData.purchaseVehicleData.imageUrl
                      ? tradeInData.purchaseVehicleData.imageUrl
                      : CarIconList
                  }
                  alt={tradeInData.purchaseVehicleData.modelName}
                />
                <DecisionVehicle>
                  <DecisionName>
                    {formatVehicle(
                      tradeInData.purchaseVehicleData.brandName,
                      tradeInData.purchaseVehicleData.modelName.toUpperCase(),
                      tradeInData.purchaseVehicleData.modelYear,
                    )}
                  </DecisionName>
                  <DecisionDescription>
                    {tradeInData.purchaseVehicleData.versionName}
                  </DecisionDescription>
                </DecisionVehicle>
                <DecisionPrice>
                  <DecisionValue>
                    <LabelsPrice>{t("VehicleValue")}</LabelsPrice>
                    {`R$ ${NumberHelper.toFormatString(
                      tradeInData.purchaseVehicleData?.price,
                      0,
                      "--",
                    )}`}
                  </DecisionValue>
                  {tradeInData.purchaseVehicleData.paymentMethod ===
                  PaymentMethodEnum.Financed ? (
                    <FinancedValue>
                      <LabelsPrice>{t("AmountFinanced")}</LabelsPrice>
                      {`R$ ${NumberHelper.toFormatString(
                        tradeInData.purchaseVehicleData?.amountFinanced,
                        0,
                        "--",
                      )}`}
                    </FinancedValue>
                  ) : null}
                </DecisionPrice>
              </DecisionDetails>
            </div>
          </Decision>
          <Customer>
            <CustomerInfo>
              <CustomerName>{tradeInData.customerData?.name}</CustomerName>
              <CustomerEmail>
                <CoxIcon name={"mail"} width={14} height={14} />
                <StyledP>{tradeInData.customerData?.email}</StyledP>
              </CustomerEmail>
              <CustomerPhone>
                <CoxIcon name={"phone"} width={14} height={14} />
                <StyledP>
                  {tradeInData.customerData.phone
                    ? formatCellPhoneNumber(tradeInData.customerData.phone)
                    : ""}
                </StyledP>
              </CustomerPhone>
            </CustomerInfo>
            <WhatsAppContainer>
              <WhatsAppButton
                customer={tradeInData.customerData}
                vehicle={tradeInData.purchaseVehicleData}
                dealerName={dealershipName}
              />
            </WhatsAppContainer>
          </Customer>
        </Details>
      </CardContainer>
    </>
  );
};
