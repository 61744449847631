import { CoxIcon } from "c4u-web-components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useChecklistContext } from "../../../hooks";
import { EqualIcon, LoadingBlue } from "../../assets/";
import { DebtsModal } from "../../atoms";

import {
  AlertIcon,
  AlertLink,
  AlertMessage,
  AlertText,
  CardContainer,
  Equal,
  LoadingContainer,
  LoadingMessage,
  LoadingSVG,
  PriceDetails,
  PriceItem,
  PriceLabel,
  PriceValue,
  PriceValueContainer,
} from "./vehicle-debts.style";

interface IDebtsProps {
  hasProblemsConsultingDebts: boolean | null;
  requestedPrice: number;
  renavam: string;
  vehicleDebts: any;
}

export const VehicleDebtCard: React.FC<IDebtsProps> = ({
  hasProblemsConsultingDebts,
  requestedPrice,
  renavam,
  vehicleDebts,
}) => {
  const { t } = useTranslation();
  const { debts, searchDebts, setHasProblemConsultingDebts, setDebts } = useChecklistContext();
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorRequest, setErrorRequest] = useState(false);

  const handleSearchDebts = async () => {
    try {
      setLoading(true);
      await searchDebts(renavam);
      setErrorRequest(false);
      setHasProblemConsultingDebts(false);
    } catch (error) {
      console.error("Error", error);
      setErrorRequest(true);
      setHasProblemConsultingDebts(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (renavam.length === 11) handleSearchDebts();
    if (renavam.length < 11) {
      setHasProblemConsultingDebts(null);
      setDebts([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renavam]);

  const CalculateTotal = ({ reference, value }: any) => {
    const calculate = reference - value;
    return calculate;
  };
  const handleRetry = () => {
    setLoading(true);
    handleSearchDebts();
  };
  if (loading) {
    return (
      <LoadingContainer>
        <LoadingSVG src={LoadingBlue} />
        <LoadingMessage>{t("WaitSearch")}</LoadingMessage>
      </LoadingContainer>
    );
  }

  const totalDebts = () => {
    let total = 0;
    if (debts && debts.length) {
      total = debts.reduce((sum, debt: any) => sum + debt.amount, 0);
    } else if (vehicleDebts.length > 0) {
      total = vehicleDebts.reduce((sum: any, debt: any) => sum + debt.amount, 0);
    }

    return total;
  };
  const formatCurrency = (value: number) => {
    return value.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
  };

  return (
    <CardContainer>
      {(hasProblemsConsultingDebts && vehicleDebts.length <= 0) ||
      (errorRequest && vehicleDebts.length <= 0) ? (
        <>
          <AlertMessage>
            <AlertIcon>
              <CoxIcon name={"danger-color"} width={20} height={20} />
            </AlertIcon>
            <AlertText>{t("VehicleDebtsError")}</AlertText>
            <AlertLink onClick={handleRetry}>
              <CoxIcon name={"reload"} width={12} height={12} fillColor="#007bce"></CoxIcon>
              {t("Retry").toUpperCase()}
            </AlertLink>
          </AlertMessage>
        </>
      ) : (debts && debts.length > 0) || vehicleDebts.length > 0 ? (
        <>
          <AlertMessage>
            <AlertIcon>
              <CoxIcon name={"info"} fillColor="#EEA200" />
            </AlertIcon>
            <AlertText>{t("HasDebts")}</AlertText>
            <AlertLink onClick={() => setIsModalOpen(true)}>
              {t("Details").toUpperCase()}
            </AlertLink>
          </AlertMessage>
          <PriceDetails>
            <PriceItem>
              <PriceValueContainer>
                <PriceLabel>{t("ReferenceValue").toUpperCase()}</PriceLabel>
                <PriceValue color="#949494">{`${formatCurrency(requestedPrice)}`}</PriceValue>
              </PriceValueContainer>
            </PriceItem>
            <PriceItem>
              <CoxIcon name={"minus"} width={20} height={20} />
              <PriceValueContainer>
                <PriceLabel>{t("LabelDebts").toUpperCase()}</PriceLabel>
                <PriceValue color="#949494">{`-${formatCurrency(totalDebts())}`}</PriceValue>
              </PriceValueContainer>
            </PriceItem>
            <PriceItem>
              <Equal src={EqualIcon}></Equal>
              <PriceValueContainer>
                <PriceLabel>{t("TotalValue").toUpperCase()}</PriceLabel>
                <PriceValue color="#5A646E">
                  {formatCurrency(
                    CalculateTotal({ reference: requestedPrice, value: totalDebts() }),
                  )}
                </PriceValue>
              </PriceValueContainer>
            </PriceItem>
          </PriceDetails>
        </>
      ) : (
        <AlertMessage>
          <AlertIcon>
            <CoxIcon name={"checked"} />
          </AlertIcon>
          <AlertText>{t("HasNoDebts")}</AlertText>
        </AlertMessage>
      )}
      <DebtsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        debts={debts && debts.length > 0 ? debts : vehicleDebts}
      />
    </CardContainer>
  );
};
