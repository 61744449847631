import { ButtonPrimary, FilterAtom, theme } from "c4u-web-components";
import { useFormik } from "formik";
import React, { useCallback, useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { TradeinStatusHelper } from "../../../helper";
import { useProductsTradein, useSessionContext } from "../../../hooks";
import {
  TradeInProgress,
  GetPaginatedTradeInRequest,
  IGetPaginatedTradeInRequest,
  IGetPaginatedTradeInResponse,
  IPagedResponseBase,
} from "../../../models";
import {
  FormikControlCalendarAtom,
  FormikControlTradeinAtom,
  HeaderDealerAtom,
} from "../../atoms";

import { CleanFiltersButton, ContainerDealerMolecule } from "./header-dealer.molecule.style";

interface IProps {
  setPaginationData?: (v: IPagedResponseBase | undefined) => void;
  setFilterValues?: (v: IGetPaginatedTradeInRequest | undefined) => void;
  setPageNumber?: (v: number, isFilter?: boolean) => void;
  hasFilters?: boolean;
  getTradeInList?: (data: IGetPaginatedTradeInResponse[]) => void;
  pageSize?: number;
  isLoading?: boolean;
}

export const HeaderDealerMolecule: React.FC<IProps> = (props: IProps) => {
  const { t } = useTranslation();
  const { getPaginatedTradeIn } = useProductsTradein();
  const { handleFormikException } = useSessionContext();

  const {
    setPaginationData: setPaginationDataProps,
    setFilterValues,
    setPageNumber,
    hasFilters,
    getTradeInList,
    pageSize,
    isLoading,
  } = props;

  const [filterIsOpen, setFilterIsOpen] = useState(false);

  const validations = Yup.object<IGetPaginatedTradeInRequest>({
    search: Yup.string().notRequired(),
    startDate: Yup.string().notRequired(),
    finalDate: Yup.string().notRequired(),
    page: Yup.number().required(),
    pageSize: Yup.number().required(),
    tradeInProgress: Yup.number<TradeInProgress>().notRequired(),
  });

  const initialValues = useMemo<IGetPaginatedTradeInRequest>(
    () => ({
      search: "",
      startDate: "",
      finalDate: "",
      tradeInProgress: "",
      page: 1,
      pageSize: pageSize ?? 5,
    }),
    [pageSize],
  );

  const formik = useFormik<IGetPaginatedTradeInRequest>({
    initialValues,
    onSubmit: async (values, { setErrors }) => {
      try {
        if (!isLoading) {
          const request = new GetPaginatedTradeInRequest({
            ...values,
            page: 1,
          });
          const { data, ...paginationData } = await getPaginatedTradeIn(request);
          getTradeInList!(data);
          if (setPageNumber) setPageNumber(1, true);
          if (setFilterValues) setFilterValues(values);
          if (setPaginationDataProps) setPaginationDataProps(paginationData);
        }
      } catch (e) {
        handleFormikException(e, setErrors);
        getTradeInList!([]);
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  const handleCleanFilters = useCallback(() => {
    formik.setValues(initialValues);
  }, [formik, initialValues]);

  return (
    <ContainerDealerMolecule>
      <HeaderDealerAtom title={t("TradeInVehicles")} />
      {hasFilters && (
        <Row>
          <Col lg={12}>
            <Form onSubmit={formik.handleSubmit}>
              <Form.Row>
                <FormikControlTradeinAtom
                  formik={formik}
                  type="search-submit"
                  property="search"
                  sm={6}
                  md={7}
                  lg={8}
                  disabled={isLoading}
                />
                <Col sm={4} md={4}>
                  <FilterAtom
                    filterText={t("Filter")}
                    filterIsOpen={filterIsOpen}
                    setFilterIsOpen={setFilterIsOpen}
                    color={theme.colors.BlueNavy}
                  />
                </Col>
              </Form.Row>

              {filterIsOpen && (
                <Form.Row className="d-flex align-items-end">
                  <FormikControlCalendarAtom
                    label={t("StartDate")}
                    property="startDate"
                    formik={formik}
                    xs={6}
                    lg={2}
                  />
                  <FormikControlCalendarAtom
                    label={t("FinalDate")}
                    property="finalDate"
                    formik={formik}
                    xs={6}
                    lg={2}
                  />
                  <FormikControlTradeinAtom
                    type={"select"}
                    data={TradeinStatusHelper.getStatusCombos(t)}
                    label={t("ProposalStatus")}
                    property={"tradeInProgress"}
                    formik={formik}
                    placeholder={t("AllStatus")}
                    sm={6}
                    lg={3}
                  />

                  <Form.Group
                    as={Col}
                    xs={{ span: 4, offset: 1 }}
                    sm={{ span: 2, offset: 0 }}
                    md={2}
                    xl={1}
                    className="pb-2 text-center"
                  >
                    <ButtonPrimary
                      type="submit"
                      sizex="sm"
                      sizey="thin"
                      loading={formik.isSubmitting || isLoading}
                      disabled={isLoading}
                    >
                      {t("OK")}
                    </ButtonPrimary>
                  </Form.Group>

                  <Form.Group as={Col} xs={6} sm={4} lg={3} className="pb-2">
                    <CleanFiltersButton
                      disabled={isLoading}
                      onClick={formik.isSubmitting ? undefined : handleCleanFilters}
                    >
                      {t("CleanFilters")}
                    </CleanFiltersButton>
                  </Form.Group>
                </Form.Row>
              )}
            </Form>
          </Col>
        </Row>
      )}
      <hr />
    </ContainerDealerMolecule>
  );
};
