import { CoxIcon, NumberHelper } from "c4u-web-components";
import React from "react";
import { useTranslation } from "react-i18next";

import { formatCellPhoneNumber, formatPlate, formatVehicle } from "../../../helper";
import { ICustomer, IPurchaseVehicle, ITradeInVehicleResponse } from "../../../models";
import { CarouselOfCarPhotosAtom } from "../../atoms";
import { WhatsAppButton } from "../../atoms/whatsapp-button/whatsapp-button.atom";

import {
  BoxContact,
  BoxName,
  BoxVehicle,
  ContactInformation,
  DataUserInformation,
  DataVehicle,
  LineHr,
  OtherCarInformation,
  PhotosVehicle,
  TitleGenericCard,
  VehicleAndUserInformation,
  VehicleInformation,
  VehicleInformationData,
} from "./vehicle-information.molecule.style";

type VehicleInformationMoleculeTypes = {
  tradeInVehicle: ITradeInVehicleResponse;
  customer: ICustomer;
  dealershipName: string;
  photos: Array<{ imageId: number; url: string }>;
  purchaseVehicle: IPurchaseVehicle;
  resetPhotos: () => void;
};

export const VehicleInformationMolecule = ({
  tradeInVehicle,
  customer,
  dealershipName,
  photos,
  purchaseVehicle,
  resetPhotos,
}: VehicleInformationMoleculeTypes) => {
  const { t } = useTranslation();

  return (
    <>
      <PhotosVehicle>
        <CarouselOfCarPhotosAtom photos={photos} resetPhotos={resetPhotos} />
      </PhotosVehicle>
      <VehicleAndUserInformation>
        <BoxVehicle>
          <VehicleInformation>
            {formatVehicle(
              tradeInVehicle.brandName,
              tradeInVehicle.modelName,
              tradeInVehicle.versionName,
              tradeInVehicle.hp,
              tradeInVehicle.modelYear,
            ).toUpperCase()}
          </VehicleInformation>
        </BoxVehicle>

        <VehicleInformationData>
          <DataVehicle>
            <TitleGenericCard>{t("Plate")}</TitleGenericCard>
            <OtherCarInformation>{formatPlate(tradeInVehicle.plate)}</OtherCarInformation>
          </DataVehicle>
          <DataVehicle>
            <TitleGenericCard>{t("AmountRequested")}</TitleGenericCard>
            <OtherCarInformation>
              {t("$")} {""}
              {NumberHelper.toFormatString(tradeInVehicle.requestedPrice, 0, "--")}
            </OtherCarInformation>
          </DataVehicle>
        </VehicleInformationData>

        <BoxContact>
          <BoxName>
            <OtherCarInformation title={customer.name}>{customer.name}</OtherCarInformation>
          </BoxName>
          <DataUserInformation>
            <CoxIcon name={"mail"} width={18} height={18} />
            <TitleGenericCard title={customer.email}>{customer.email}</TitleGenericCard>
          </DataUserInformation>

          <ContactInformation>
            <DataUserInformation>
              <CoxIcon name={"phone"} width={18} height={18} />
              <TitleGenericCard>{formatCellPhoneNumber(customer.phone)}</TitleGenericCard>
            </DataUserInformation>
            <WhatsAppButton
              customer={customer}
              vehicle={purchaseVehicle}
              dealerName={dealershipName}
            />
          </ContactInformation>
        </BoxContact>
      </VehicleAndUserInformation>
      <LineHr />
    </>
  );
};
