import { ButtonPrimary, ButtonSecondary } from "c4u-web-components";
import styled, { css } from "styled-components";

type VariantType = "primary" | "default";
interface IPriceProps {
  readonly variant?: VariantType;
}

interface IActionsContainerProps {
  readonly direction: "vertical" | "horizontal";
  readonly wrapper?: boolean;
}

interface IFakeInputProps {
  readonly danger?: boolean;
  readonly padding?: "sm" | "md";
}

interface IColorRequestedAt {
  readonly isNotDrs?: boolean;
}

const variantStyles = (variant: VariantType = "default") =>
  ({
    default: css`
      color: #4a4a4a;
    `,
    primary: css`
      color: ${props => props.theme.colors.BlueNavy};
    `,
  })[variant];

export const FooterContainer = styled.div`
  background-color: #f2f2f3;
  min-height: 200px;
  position: fixed;
  bottom: 0;
  left: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  gap: 24px;
  -webkit-box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.15);
  z-index: 1;

  @media (max-width: 1340px) {
    gap: 8px;
  }

  @media (max-width: 1000px) {
    z-index: 1;
  }
`;
export const PricesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #ffffff;
  padding: 16px;
  border: 1px solid #e1e1e2;
  border-radius: 4px;
  width: 320px;
  min-height: 194px;
  justify-content: center;
  align-items: center;
`;
export const TradeInPricesContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-start;

  img {
    width: 41px;
    height: 55px;
  }
`;

export const TradeInPrices = styled.div<IColorRequestedAt>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  p {
    font-family: ${props => props.theme.fontFamily.OpenSans};
    color: ${({ isNotDrs }) => (isNotDrs ? "#a8aaac" : "#4A4A4A")};
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    margin: 0;
  }
`;

export const Title = styled.span`
  font-family: ${props => props.theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #4a4a4a;
  line-height: 20px;
`;

export const InformationPrice = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
  gap: 5px;
`;

export const Price = styled.span<IPriceProps>`
  font-family: ${props => props.theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  ${({ variant }) => variantStyles(variant)}
`;

export const HighlightedPrice = styled.span`
  font-family: ${props => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: ${props => props.theme.colors.BlueNavy};
`;

export const InputsContainer = styled.div`
  font-family: ${props => props.theme.fontFamily.OpenSans};
  font-size: 14px;
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const InputErrorContainer = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 10px;
    font-family: ${({ theme }) => theme.fontFamily.OpenSans};
    color: #c4390a;
  }
`;

export const ResaleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ActionContainer = styled.div<IActionsContainerProps>`
  display: flex;
  align-items: end;
  flex-direction: ${({ direction }) => (direction === "vertical" ? "column" : "row")};
  gap: 50px;
  font-family: ${({ theme }) => theme.fontFamily.Montserrat};
  ${({ wrapper }) =>
    wrapper &&
    css`
      min-height: 192px;
    `}
`;

export const PDFButton = styled(ButtonSecondary)`
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  :hover {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.BlueNavy};
  }

  :focus {
    text-decoration: none;
  }

  :disabled {
    cursor: not-allowed;
    pointer-events: auto;

    :hover {
      color: #6c757d;
    }
  }
`;

export const CustomPrimaryButton = styled(ButtonPrimary)`
  width: 220px;

  :focus {
    text-decoration: none;
  }

  :disabled {
    cursor: not-allowed;
    pointer-events: auto;
    z-index: -1;

    :hover {
      color: #6c757d;
      background-color: #1f3e74;
    }
  }
`;

export const CustomButtonLink = styled.button`
  color: #808285;
  background: none;
  border: none;
  cursor: pointer;
  font-family: ${props => props.theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  padding: 10px;

  :hover {
    text-decoration: none;
  }

  :focus {
    text-decoration: none;
  }
`;

export const BodyModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 16px;

  h2 {
    font-size: 20px;
    font-family: ${({ theme }) => theme.fontFamily.Montserrat};
    color: ${({ theme }) => theme.colors.BlueNavy};
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.15px;
    font-weight: 700;
  }
`;
export const ActionsModal = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  font-family: ${({ theme }) => theme.fontFamily.Montserrat};
  width: 240px;
`;

export const InputFrame = styled.div`
  padding: 14px 8px;
  border: 1px solid #e1e1e2;
  border-radius: 4px;
  background-color: white;
  display: flex;
  height: 54px;
`;

export const FakeInput = styled.fieldset<IFakeInputProps>`
  background-color: white;
  border: 1px solid #e1e1e2;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 155px;
  padding: ${({ padding }) => (padding === "sm" ? "4px 8px 12px 8px" : "4px 16px 12px 16px")};
  color: ${({ danger }) => (danger ? "#C4390A" : "#4A4A4A")};
  gap: 2px;
  margin: 0;

  span {
    font-family: ${({ theme }) => theme.fontFamily.Montserrat};
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.15px;
  }

  small {
    font-size: 12px;
    font-family: ${({ theme }) => theme.fontFamily.OpenSans};
    line-height: 20px;
  }

  legend {
    margin: 0;
    width: fit-content;
    z-index: 1;
    font-size: 12px;
    padding: 0px 2px;
    color: #808285;
    background: transparent;
    text-overflow: ellipsis;
    pointer-events: none;
  }
`;

export const MessageTradedValue = styled.span`
  font-family: ${props => props.theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: ${props => props.theme.colors.BlueNavy};
  line-height: 20px;
`;
