import styled from "styled-components";
import { ButtonPrimary } from 'c4u-web-components';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); 
  display: flex;
  align-items: center;
  justify-content: center;
 
`;

export const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  max-width: 700px;
  width: 90%;
  max-height: 600px;
  display:flex;
  font-family: Open Sans;
  flex-direction:column;
  overflow-y: scroll;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
   &::-webkit-scrollbar {
    display: none; 
  }
`;

export const Header = styled.div`
    display: flex;
    flex-direction:column;
    border-bottom: 2px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 20px;
`;
export const ColumnTitle = styled.h2`
    display:flex;
    justify-content: space-between;
    margin: 0;
    font-size: 1.5em;
`
export const Columns = styled.h2`
    font-size:16px;
    font-weight: bold;
    color: ${(props) => props.theme.colors.Dark};

`
export const Title = styled.h2`
  margin: 0;
  font-weight: bold;
  color: ${(props) => props.theme.colors.grey80};
  font-size: 1.5em;
  text-align: center;
  margin: 20px 0 50px 0;
`;

export const CloseButton = styled.button`
  align-self: end;
  background: transparent;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
  color: ${(props) => props.theme.colors.Dark};

`;

export const DebtItem = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 10px;
`;

export const DebtDetails = styled.div`
  width: 70%;
`;

export const DebtAmount = styled.div`
  width: 30%;
  text-align: right;
  font-weight: 800;
  font-size: 1.2em;
  color: ${(props) => props.theme.colors.BlueNavy};
`;

export const DebtTitle = styled.h3`
  margin: 0 0 30px 0;
  font-weight: 700;
  color: ${(props) => props.theme.colors.Dark};
  font-size: 1.2em;
`;
export const DebtLabel = styled.p`
  margin:0 0 10px 0;
  font-size: 11px;
  font-weight:bold;
  color: ${(props) => props.theme.colors.grey80};
`
export const DebtDetail = styled.p`
  margin: 5px 0 30px 0;
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.Dark};
`;
export const TotalSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
  padding-top: 10px;
  margin-top: 20px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.BlueNavy};
`;

export const TotalLabel = styled.span`
  font-size: 1em;
  margin-right:10px;
  color: ${(props) => props.theme.colors.grey80};
`;
export const TotalValue = styled.p`
  margin: 0;
  font-weight: 900;
  font-family: Open Sans;
  font-size: 20px;
`
export const Footer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const StyledButtonPrimary = styled(ButtonPrimary)`
  width: 200px;
`
