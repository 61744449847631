import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";

import { formatDate } from "../../../helper";

import {
  CloseButton,
  ColumnTitle,
  Columns,
  DebtAmount,
  DebtDetail,
  DebtDetails,
  DebtItem,
  DebtLabel,
  DebtTitle,
  Footer,
  Header,
  ModalContent,
  ModalOverlay,
  StyledButtonPrimary,
  Title,
  TotalLabel,
  TotalSection,
  TotalValue,
} from "./debts-modal.style";

interface DebtsModalProps {
  isOpen: boolean;
  onClose: () => void;
  debts: any[];
}

export const DebtsModal: React.FC<DebtsModalProps> = ({ isOpen, onClose, debts }) => {
  const { t } = useTranslation();

  function toTitleCase(str: string): string {
    return str
      .toLowerCase()
      .split(" ")
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const totalAmount = debts.reduce((total, debt) => total + debt.amount, 0);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Detalhes dos Débitos"
      style={{
        overlay: {
          backgroundColor: "transparent",
          zIndex: 1000,
        },
        content: {
          inset: "0",
          position: "static",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "none",
          overflow: "hidden",
        },
      }}
    >
      <ModalOverlay>
        <ModalContent>
          <CloseButton onClick={onClose}>✕</CloseButton>
          <Header>
            <Title>{t("DebtsResume")}</Title>
            <ColumnTitle>
              <Columns>{t("Debts").toUpperCase()}</Columns>
              <Columns>{t("Value").toUpperCase()}</Columns>
            </ColumnTitle>
          </Header>

          {debts.map((debt, index) => (
            <DebtItem key={index}>
              <DebtDetails>
                <DebtTitle>{toTitleCase(debt.title)}</DebtTitle>
                <DebtDetail>
                  <DebtLabel>{t("DueDate")}</DebtLabel>{" "}
                  {debt.due_Date ? formatDate(debt.due_Date) : null}
                </DebtDetail>
                <DebtDetail>
                  <DebtLabel>{t("Description")}</DebtLabel>
                  {debt.description}
                </DebtDetail>
              </DebtDetails>
              <DebtAmount>
                {debt.amount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
              </DebtAmount>
            </DebtItem>
          ))}
          <TotalSection>
            <TotalLabel>{t("TotalAmount").toUpperCase()}</TotalLabel>
            <TotalValue>
              {" "}
              {totalAmount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
            </TotalValue>
          </TotalSection>
          <Footer>
            <StyledButtonPrimary profile="manheim" onClick={onClose}>
              Fechar
            </StyledButtonPrimary>
          </Footer>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
