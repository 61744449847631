import styled from "styled-components"

export const ButtonWhatsApp = styled.button`
  width: 100%;
  height: 10%;
  border-radius: 50px;

  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  color: ${(props) => props.theme.colors.MediumGray};

  background-color: #fff;
  border: 1px #4aa434 solid;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: auto;

  path {
    fill: #4aa434;
  }

  @media (max-width: 1200px) {
    font-size: 10px;
    line-height: 13px;
  }

  @media (max-width: 918px) {
    font-size: 7px;
    line-height: 13px;
    gap: 5px
  }
`;
