import styled, { css } from "styled-components";

type FlexContainerProps = {
  readonly width?: number;
  readonly justify?: 'space-between' | 'center';
  readonly align?: 'center';
  readonly direction?: 'column' | 'row'
}

export const Card = styled.div`
  width: 100%;
  height: 275px;

  border: 1px solid #dee2e5;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 20px;

  border-radius: 10px;
  gap: 10px;
`
export const FlexContainer = styled.div<FlexContainerProps>`
  display: flex;
  ${({ align }) => align && css` align-items: ${align};`}
  ${({ justify }) => justify && css` justify-content: ${justify};`}
  ${({ direction }) => direction && css` flex-direction: ${direction};`}
  ${({ width }) => width && css` width: ${width}px;`}
  height: 100%;
`
