import { SpinnerCenteredAtom } from "c4u-web-components";
import React, { useEffect, useState } from "react";

import { paths } from "../../../constants";
import { useChecklistContext, useProductsTradein, useTradeinContext } from "../../../hooks";
import { IColors, IEquipments } from "../../../models";
import { ButtonBackAtom, Show } from "../../atoms";
import {
  AccordionMolecule,
  VehicleChecklistCarCardMolecule,
  ChecklistFooterMolecule,
  HeaderDealerMolecule,
} from "../../molecules";

import { BackContainer, Checklist, ContainerChecklist } from "./checklist.organisms.style";

interface IFooterData {
  tradedValue: number;
  status: number;
}

export const ChecklistOrganisms: React.FC = () => {
  const { userContext } = useTradeinContext();
  const {
    checklistData,
    setChecklistData,
    setEquipmentsCost,
    saveChecklistData,
    tradeInId,
    setSaveChecklistData,
    cleanState,
    renavamInput,
    debts,
    setHasProblemConsultingDebts,
  } = useChecklistContext();

  const { getProposalDetails, saveChecklist } = useProductsTradein();
  const [equipments, setEquipments] = useState<IEquipments[] | undefined>();

  const [totalAmountOfDamages, setTotalAmountOfDamages] = useState(0);
  const [colors, setColors] = useState<IColors[] | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [postChecklistData, setPostChecklistData] = useState({});
  const [footerData, setFooterData] = useState<IFooterData>({ tradedValue: 0, status: 0 });
  const [damageErrors, setDamageErrors] = useState<number[]>([]);
  const [renavamErrors, setRenavamErrors] = useState<string>("");
  const [colorEmptyErrors, setColorsEmptyErrors] = useState(false);
  // footer modal and static renavam
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { hasProblemConsultingDebts } = useChecklistContext();
  const [sendDataToSave, setSendDataToSave] = useState(false);
  const [isSavingChecklist, setIsSavingChecklist] = useState(false);

  const HandleSaveChecklist = async (postChecklistData: any, footerData: IFooterData) => {
    const payload = {
      tradeInId,
      negotiatedValue: footerData.tradedValue,
      status: footerData.status,
      damages: postChecklistData.damages,
      deliveryDocuments: postChecklistData.deliveryDocuments,
      colors: postChecklistData.colors,
      equipments: postChecklistData.equipments,
      renavam: renavamInput,
      tradeInVehicleDebts: debts,
      hasProblemConsultingDebts,
    };
    if (payload) {
      try {
        setIsSavingChecklist(true);
        await saveChecklist(payload);
        setIsModalVisible(true);
      } catch (error) {
        console.error(error);
      } finally {
        setIsSavingChecklist(false);
      }
    }
  };

  useEffect(() => {
    if (
      (saveChecklistData && (damageErrors.length > 0 || renavamErrors)) ||
      colorEmptyErrors
    ) {
      setSaveChecklistData(false);
      setSendDataToSave(false);
    } else if (saveChecklistData) {
      setSendDataToSave(true);
      setSaveChecklistData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveChecklistData, damageErrors, renavamErrors, colorEmptyErrors]);

  useEffect(() => {
    if (sendDataToSave && damageErrors.length === 0 && !renavamErrors && !colorEmptyErrors) {
      const saveData = async () => {
        const updatedPostChecklistData = {
          ...postChecklistData,
        };

        const updatedFooterData = {
          ...footerData,
        };

        HandleSaveChecklist(updatedPostChecklistData, updatedFooterData);
        setSendDataToSave(false);
      };

      saveData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendDataToSave, damageErrors, renavamErrors, colorEmptyErrors]);

  useEffect(() => {
    if (tradeInId) {
      setLoading(true);
      cleanState();
      setRenavamErrors("");
      setDamageErrors([]);
      setColorsEmptyErrors(false);
      const fetchData = async () => {
        try {
          const data = await getProposalDetails(Number(tradeInId));
          setChecklistData(data);
          const equips = data.tradeInVehicleData.vehicleEquipments;
          setEquipments(equips);
          const colors = data.tradeInVehicleData.vehicleColors;
          setColors(colors);
          setLoading(false);
          setHasProblemConsultingDebts(data.tradeInVehicleData.hasProblemConsultingDebts);
        } catch (error) {
          console.error("Error ", error);
          setLoading(false);
        }
      };
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradeInId]);

  if (!userContext) {
    return null;
  }

  return (
    <Checklist>
      <Show
        when={!loading}
        fallback={<SpinnerCenteredAtom classNames="mt-5"></SpinnerCenteredAtom>}
      >
        <>
          <HeaderDealerMolecule />
          <BackContainer>
            <ButtonBackAtom title="VOLTAR" backScreen={paths.listLead()} />
          </BackContainer>
          <ContainerChecklist>
            <VehicleChecklistCarCardMolecule
              tradeInData={checklistData}
              dealershipName={userContext.dealership.name}
              renavamErrors={renavamErrors}
              setRenavamErrors={setRenavamErrors}
              isModalVisible={isModalVisible}
              proposalId={tradeInId}
            />
            {equipments && colors && (
              <AccordionMolecule
                equipments={equipments}
                setEquipments={setEquipments}
                colors={colors}
                setColors={setColors}
                setTotalDamages={total => setTotalAmountOfDamages(total)}
                setEquipmentsCost={setEquipmentsCost}
                setPostChecklistData={setPostChecklistData}
                saveChecklistData={saveChecklistData}
                damageErrors={damageErrors}
                setDamageErrors={setDamageErrors}
                colorsEmptyErrors={colorEmptyErrors}
                setColorsEmptyErrors={setColorsEmptyErrors}
              />
            )}
          </ContainerChecklist>
          <ChecklistFooterMolecule
            tradeInId={tradeInId}
            loadingButton={isSavingChecklist}
            status={checklistData?.checklist?.status ?? null}
            key={tradeInId}
            requestedPrice={checklistData?.tradeInVehicleData.requestedPrice}
            damages={totalAmountOfDamages}
            paymentMethod={checklistData?.purchaseVehicleData.paymentMethod}
            setFooterData={setFooterData}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
          />
        </>
      </Show>
    </Checklist>
  );
};
