import { Modal } from "react-bootstrap";
import styled from "styled-components";

interface IProps {
  backgroundColor: string;
  borderColor: string;
}

export const StatusInformation = styled.div`
  width: 25%;
  height: 100%;
  background-color: ${props => props.theme.colors.Grey30};
  border-radius: 0px 10px 10px 0px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

export const ProposalIdentifier = styled.div`
  span {
    font-size: 14px;
    color: #4a4a4a;
    font-family: Montserrat;
  }
`;

export const AddedDate = styled.p`
  display: flex;
  align-items: center;
  font-size: 11px;
  align-self: center;
  color: #888;
  gap: 4px;
  margin: 0 0 10px 0;
`;

export const IconCalendar = styled.img`
  width: 10px;
  height: 11px;
  margin: 0 2px 2px 0;
`;

export const NegotiationStatus = styled.div<IProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: ${props => props.backgroundColor};
  border-radius: 20px;
  border: 1px solid ${props => props.borderColor};
  padding: 5px;

  div {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: ${props => props.borderColor};
  }

  span {
    font-size: 12px;
    color: ${props => props.borderColor};
    font-weight: 700;
  }
`;

export const SeeHistorical = styled.button`
  text-decoration: underline;
  border: none;
  background-color: ${props => props.theme.colors.Grey30};
  color: #0585c8;
  font-size: 14px;
  font-weight: 700;
`;

export const StyledModal = styled(Modal)`
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  overflow-y: hidden !important;

  scrollbar-color: #4a4a4a transparent;
  scrollbar-width: thin;
  scrollbar-gutter: stable;

  .table {
    margin-bottom: 0;
  }

  .modal-content {
    border: none;
    padding: 0px 35px 35px 35px;
  }

  .modal-body {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 2rem 0 1rem 0;
  }
`;

export const TitleModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  span {
    font-family: Montserrat;
    font-weight: 700;
    font-size: 20px;
    color: #1f3e74;
    line-height: 28px;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
`;

export const BoxCarInformation = styled.div`
  width: 100%;
  padding: 0 1rem 1rem 1rem;
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
`;

export const CarInformartion = styled.div`
  display: flex;
  gap: 20px;
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;

  span:first-child {
    width: 100%;
    font-family: ${props => props.theme.fontFamily.Montserrat};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    color: ${props => props.theme.colors.MediumGray};
    word-wrap: normal;
  }

  span:nth-child(2) {
    width: 100%;
    font-family: ${props => props.theme.fontFamily.Montserrat};
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    color: ${props => props.theme.colors.Blue40};
    word-wrap: normal;
  }
`;

export const DetailsOfThatProposal = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

export const Identifier = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;

  span {
    font-size: 14px;
    color: #4a4a4a;
    font-family: Montserrat;

    @media (max-width: 1407px) {
      font-size: 12px;
    }

    @media (max-width: 1200px) {
      font-size: 8px;
    }
  }
`;

export const List = styled.div`
  overflow-y: scroll;
  height: 300px;
  width: 100%;
  margin: auto auto 30px auto;
  border-bottom: 1px solid rgba(225, 225, 226, 0.35);
  border-right: 1px solid rgba(225, 225, 226, 0.35);
  border-left: 1px solid rgba(225, 225, 226, 0.35);

  scrollbar-color: #4a4a4a transparent;
  scrollbar-width: thin;
  scrollbar-gutter: stable;

  table {
    padding: 12px;
  }

  thead {
    background-color: rgba(225, 225, 226, 0.35);
    color: rgba(225, 225, 226, 0.35);
  }

  tbody tr:nth-child(even) {
    background: rgba(225, 225, 226, 0.35);
  }

  tbody tr:nth-child(odd) {
    background: #ffffff;
  }

  tr {
    color: rgba(225, 225, 226, 0.35);
  }
`;

export const InformationHeaderList = styled.th`
  font-family: Montserrat;
  font-weight: 700;
  font-size: 14px;
  color: #1f3e74;
  line-height: 28px;
`;

export const ChangeInformation = styled.th`
  font-family: Montserrat;
  font-weight: 700;
  font-size: 14px;
  color: #4a4a4a;
  line-height: 28px;
`;

export const ChangeDateInformation = styled.th`
  font-family: Montserrat;
  font-weight: 400;
  font-size: 14px;
  color: #4a4a4a;
  line-height: 28px;
`;

export const EmptyList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: ${props => props.theme.fontFamily.OpenSans};
  font-size: 16px;
  color: #4a4a4a;
  padding: 30px;
`;
