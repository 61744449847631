import { Skeleton } from '@mui/material'
import React from 'react'
import { Card, FlexContainer } from './skeleton.style'

type SkeletonCardTypes = {
  type: 'cardListLeads'
}

export const SkeletonLoading = ({ type }: SkeletonCardTypes) => {
  switch(type) {
    case 'cardListLeads':
      return (
        <Card>
          <Skeleton variant="rounded" animation="wave" width={200} height={230} />
          <FlexContainer justify='space-between' direction='column'>
            <div>
              <Skeleton variant="text" animation="wave" width={300} />
              <Skeleton variant="text" animation="wave" width={300} />
            </div>

            <div>
              <Skeleton variant="text" animation="wave" width={250} />
              <Skeleton variant="text" animation="wave" width={250} />
            </div>
            <div>
              <Skeleton variant="text" animation="wave" width={300} />
              <Skeleton variant="text" animation="wave" width={300} />
              <Skeleton variant="text" animation="wave" width={300} />
            </div>
          </FlexContainer>
          <FlexContainer width={350} align='center' justify='space-between' direction='column'>
            <FlexContainer align='center' direction='column'>
              <Skeleton variant="text" animation="wave" width={200} />
              <Skeleton variant="text" animation="wave" width={100} />
            </FlexContainer>

            <div>
              <Skeleton variant="text" animation="wave" width={200} />
              <Skeleton variant="text" animation="wave" width={200} />
              <Skeleton variant="text" animation="wave" width={200} />
            </div>
          </FlexContainer>
          <FlexContainer justify='space-between' direction='column'>
              <Skeleton variant="rounded" animation="wave" width={250} height={50} />
              <Skeleton variant="rounded" animation="wave" width={250} height={120} />
              <Skeleton variant="rounded" animation="wave" width={250} height={40} />
          </FlexContainer>
        </Card>
      )
      default:
        return null
  }

}
