import styled from "styled-components";

export const HeaderTitleWrapper = styled.div`
  margin: 28px 0 21px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleDealer = styled.span`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;

  color: ${(props) => props.theme.colors.BlueNavy};
`;
