import { Combo, Translator } from "c4u-web-components";

import { TradeInProgress } from "../models";

export class TradeinStatusHelper {
  static getStatusName(status: TradeInProgress, t: Translator) {
    switch (status) {
      case TradeInProgress.Awaiting:
        return t("Awaiting");
      case TradeInProgress.InProgress:
        return t("InProgress");
      case TradeInProgress.Approved:
        return t("Approved");
      case TradeInProgress.WithChanges:
        return t("WithChanges");
      case TradeInProgress.ToPassOn:
        return t("ToPassOn");
      case TradeInProgress.FinancingInProgress:
        return t("FinancingInProgress");
      case TradeInProgress.FinancingRejected:
        return t("FinancingRejected");
      case TradeInProgress.FinancingCompleted:
        return t("FinancingCompleted");
      default:
        return t("");
    }
  }

  static getStatusCombos(t: Translator) {
    return Object.entries(TradeInProgress)
      .filter(([k, _]) => !isNaN(parseInt(k)))
      .map(
        ([k, _]) =>
          new Combo({
            title: this.getStatusName(parseInt(k), t),
            value: k,
          }),
      );
  }
}
