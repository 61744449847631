import styled from "styled-components";
export const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  color: #007bce; 
  cursor: pointer;
  font-family: 'Roboto', sans-serif; 
  font-weight: bold;
  font-size: 12px;
  text-decoration: underline;
  
  &:hover {
    text-decoration: underline;
  }
`;

export const ArrowIcon = styled.span`
  display: inline-block;
  border: solid #007bce;
  border-width: 0 2px 2px 0;
  padding: 3px;
  margin-right: 5px;
  transform: rotate(135deg);
`;