import { FloatLabelInputOrganism } from "@dtbr-generic/components";
import { CoxIcon, ModalDefault, NumberHelper, Tooltip } from "c4u-web-components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { paths } from "../../../constants";
import { useChecklistContext, useProductsTradein, useSkipFirstEffect } from "../../../hooks";
import {
  IGetPriceAdvisorDetailsResponseModel,
  IGetTradeInPricesResponseModel,
  // PaymentMethodEnum,
  CheckListStatusEnum,
} from "../../../models";
import { LogoKbb } from "../../assets";
import { LoadingSpinner, Show } from "../../atoms";
import { ChecklistStatus } from "../../atoms/checklist-status/checklist-status.atom";

import {
  ActionContainer,
  ActionsModal,
  BodyModalContainer,
  FakeInput,
  FooterContainer,
  HighlightedPrice,
  InputsContainer,
  // PDFButton,
  Price,
  PricesContainer,
  ResaleContainer,
  CustomPrimaryButton,
  Title,
  TradeInPrices,
  TradeInPricesContainer,
  InputErrorContainer,
  InformationPrice,
  MessageTradedValue,
} from "./checklist-footer.molecule.styles";

type CheckListFooterMolecule = {
  damages: number;
  requestedPrice: number;
  status: CheckListStatusEnum | null;
  tradeInId: number;
  paymentMethod: number;
  setFooterData: (data: any) => void;
  isModalVisible: boolean;
  setIsModalVisible: (isVisible: boolean) => void;
  loadingButton: boolean;
};

export const ChecklistFooterMolecule: React.FC<CheckListFooterMolecule> = ({
  damages,
  requestedPrice,
  status,
  paymentMethod,
  setFooterData,
  isModalVisible,
  setIsModalVisible,
  loadingButton,
}) => {
  const {
    statusProposal,
    setStatusProposal,
    checklistData,
    equipmentsCost,
    setSaveChecklistData,
    renavamLoading,
    renavamInput,
    tradeInId,
  } = useChecklistContext();

  const { getTradeInPrices, getPriceAdvisorDetails } = useProductsTradein();
  const [tradeInPrices, setTradeInPrices] = useState<IGetTradeInPricesResponseModel>();
  const [resalePrices, setResalePrices] = useState<IGetPriceAdvisorDetailsResponseModel>();
  const [isLoading, setIsLoading] = useState(true);
  const [tradedValue, setTradedValue] = useState(
    NumberHelper.toFormatCurrency(requestedPrice),
  );
  const [toPassOn, setToPassOn] = useState(false);
  const [floatTradedValue, setFloatTradedValue] = useState<number>(requestedPrice);

  const history = useHistory();
  const { t } = useTranslation();

  const getStatusState = (): CheckListStatusEnum => {
    if (!toPassOn && NumberHelper.toNumber(tradedValue) === requestedPrice) {
      return CheckListStatusEnum.Approved;
    } else if (toPassOn) {
      return CheckListStatusEnum.ToPassOn;
    } else if (NumberHelper.toNumber(tradedValue) !== requestedPrice) {
      return CheckListStatusEnum.WithChanges;
    } else {
      return CheckListStatusEnum.Approved;
    }
  };

  // const getSwitchTranslationKey = (state: CheckListStatusEnum): string => {
  //   switch (state) {
  //     case CheckListStatusEnum.Approved:
  //       return "Approved";
  //     case CheckListStatusEnum.WithChanges:
  //       return "WithChanges";
  //     case CheckListStatusEnum.ToPassOn:
  //       return "ToPassOn";
  //     default:
  //       return "Unknown";
  //   }
  // };

  // useEffect(() => {
  //   if (saveChecklistData) {
  //     const payloadFooter = {
  //       tradedValue: floatTradedValue,
  //       status: getSwitchState(),
  //     };

  //     if (floatTradedValue) {
  //       setFooterData(payloadFooter);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [saveChecklistData, tradedValue, floatTradedValue]);

  function handleFooterSave() {
    const payloadFooter = {
      tradedValue: floatTradedValue,
      status: getStatusState(),
    };
    setFooterData(payloadFooter);
    setSaveChecklistData(true);
  }

  useSkipFirstEffect(() => {
    async function getPrice() {
      if (equipmentsCost !== undefined) {
        setIsLoading(true);
        const tradeIn = await getTradeInPrices(tradeInId, equipmentsCost);
        const resale = await getPriceAdvisorDetails(tradeInId, equipmentsCost);
        if (tradeIn && resale) {
          setTradeInPrices(tradeIn);
          setResalePrices(resale);
        }
        setIsLoading(false);
      }
    }
    getPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentsCost]);

  const handleChange = (value: string) => {
    const inputValue = value.replace(/[^\d]/g, "");
    const floatValue = parseFloat(inputValue) / 100;
    const formattedValue = NumberHelper.toFormatCurrency(floatValue);

    setFloatTradedValue(floatValue);
    setTradedValue(formattedValue);
  };

  const finalMarginCalc =
    (resalePrices?.averagePrice ?? 0) - NumberHelper.toNumber(tradedValue) - damages;

  useEffect(() => {
    if (checklistData && checklistData.checklist) {
      setStatusProposal(false);
      if (checklistData.checklist.status === CheckListStatusEnum.ToPassOn) {
        setToPassOn(true);
      }
    }

    if (checklistData && checklistData.checklist?.negotiatedValue) {
      setFloatTradedValue(checklistData.checklist.negotiatedValue);
      setTradedValue(NumberHelper.toFormatCurrency(checklistData.checklist.negotiatedValue));
    } else {
      setTradedValue(NumberHelper.toFormatCurrency(requestedPrice));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklistData]);

  return (
    <FooterContainer>
      <Show when={!isLoading} fallback={<LoadingSpinner />}>
        <>
          {/* //# TradeInCard */}
          <PricesContainer>
            <TradeInPricesContainer>
              <img src={LogoKbb} alt="kbb-logo" />
              <TradeInPrices isNotDrs={true}>
                <Title>{t("TradeInPrice")}</Title>
                <InformationPrice>
                  <Price variant="primary">
                    {`R$ ${NumberHelper.toFormatString(
                      tradeInPrices ? tradeInPrices?.pricesUpdated.priceLow : 0,
                      0,
                      "--",
                    )} - R$ ${NumberHelper.toFormatString(
                      tradeInPrices ? tradeInPrices?.pricesUpdated.priceHigh : 0,
                      0,
                      "--",
                    )}`}
                  </Price>

                  <Tooltip
                    content={
                      <TradeInPrices>
                        <Title>{t("ExchangePriceEcommerce")}</Title>
                        <Price variant="primary">
                          {`R$ ${NumberHelper.toFormatString(
                            tradeInPrices ? tradeInPrices?.pricesDRS.priceLow : 0,
                            0,
                            "--",
                          )} - R$ ${NumberHelper.toFormatString(
                            tradeInPrices ? tradeInPrices?.pricesDRS.priceHigh : 0,
                            0,
                            "--",
                          )}`}
                        </Price>
                        <p>{tradeInPrices?.pricesDRS.requestedAt}</p>
                      </TradeInPrices>
                    }
                    width="250px"
                    placement="top"
                  >
                    <CoxIcon name={"time"} width={19} height={19} />
                  </Tooltip>
                </InformationPrice>
                <p>{tradeInPrices?.pricesUpdated.requestedAt}</p>
              </TradeInPrices>
            </TradeInPricesContainer>

            <InputsContainer>
              <FloatLabelInputOrganism
                variant="disabled"
                labelText={t("RequestedPrice")}
                value={NumberHelper.toFormatCurrency(requestedPrice)}
              />
              <div>
                <Tooltip
                  content={
                    <>
                      <MessageTradedValue>{t("MessageTradedValue")}</MessageTradedValue>
                    </>
                  }
                  triggerInput={"focus"}
                  width="200px"
                >
                  <InputErrorContainer>
                    <FloatLabelInputOrganism
                      variant={
                        tradedValue === NumberHelper.toFormatCurrency(0) ? "error" : "default"
                      }
                      labelText={t("TradedValue")}
                      placeholder={t("TradedValue")}
                      value={tradedValue}
                      onChange={({ target }) => handleChange(target.value)}
                      maxLength={14}
                    />
                    {tradedValue === NumberHelper.toFormatCurrency(0) && (
                      <span>{t("RequiredField")}</span>
                    )}
                  </InputErrorContainer>
                </Tooltip>
              </div>
            </InputsContainer>
          </PricesContainer>

          {/* //# ResaleCard */}
          <PricesContainer>
            {resalePrices && (
              <>
                <TradeInPricesContainer>
                  <img src={LogoKbb} alt="kbb-logo" />
                  <ResaleContainer>
                    <TradeInPrices>
                      <Title>{t("ResalePrice")}</Title>
                      <Price>
                        {`R$ ${NumberHelper.toFormatString(resalePrices?.priceMin, 0, "--")}
                          - R$ ${NumberHelper.toFormatString(resalePrices?.priceMax, 0, "--")}`}
                      </Price>
                    </TradeInPrices>
                    <TradeInPrices>
                      <Title>{t("DealerPrice")}</Title>
                      <HighlightedPrice>
                        {`R$ ${NumberHelper.toFormatString(resalePrices?.averagePrice, 0, "--")}`}
                      </HighlightedPrice>
                      <p>{resalePrices?.requestedAt}</p>
                    </TradeInPrices>
                  </ResaleContainer>
                </TradeInPricesContainer>
              </>
            )}
          </PricesContainer>

          {/* //# Actions */}
          <ActionContainer wrapper direction="vertical">
            <InputsContainer>
              <FakeInput danger>
                <legend>{t("Damages")}</legend>
                <small>{t("$")}</small>
                <span>{NumberHelper.toFormatString(damages, 2, "--")}</span>
              </FakeInput>
              <FakeInput>
                <legend>{t("FinalMargin")}</legend>
                <small>{t("$")}</small>
                <span>{NumberHelper.toFormatString(finalMarginCalc, 2)}</span>
              </FakeInput>

              <FakeInput padding="sm">
                <legend>Status</legend>
                <ChecklistStatus
                  setToPassOn={(value: boolean) => setToPassOn(value)}
                  checklistStatus={status}
                  status={getStatusState()}
                  negotiatedPrice={floatTradedValue}
                  requestedPrice={requestedPrice}
                />
              </FakeInput>

              {/* </InputFrame> */}
            </InputsContainer>
            <ActionContainer direction="horizontal">
              {/* <PDFButton disabled>
                <img src={CopyIcon} alt="pdf-icon" /> {t("PDFButton")}
              </PDFButton> */}
              <CustomPrimaryButton
                disabled={
                  tradedValue === NumberHelper.toFormatCurrency(0) ||
                  (renavamInput.length === 11 && renavamLoading) ||
                  statusProposal ||
                  loadingButton
                }
                type="submit"
                profile="manheim"
                onClick={handleFooterSave}
                loading={loadingButton}
              >
                {loadingButton ? "" : t("Save")}
              </CustomPrimaryButton>
            </ActionContainer>
          </ActionContainer>
        </>
      </Show>

      <ModalDefault
        centered
        title=""
        show={isModalVisible}
        onHide={() => setIsModalVisible(false)}
      >
        <BodyModalContainer>
          <CoxIcon width={30} height={30} name="approved-color" />
          <h2>{t("UpdateSuccessMessage")}</h2>
          <ActionsModal>
            {/* {paymentMethod === PaymentMethodEnum.Financed && (
              <CustomPrimaryButton profile="manheim">
                {t("FollowToFinancing")}
              </CustomPrimaryButton>
            )}
            <PDFButton>
              <img src={CopyIcon} alt="pdf-icon" /> {t("PDFButton")}
            </PDFButton> */}
            <CustomPrimaryButton
              profile="manheim"
              onClick={() => history.push(paths.listLead())}
            >
              {t("Continue")}
            </CustomPrimaryButton>
          </ActionsModal>
        </BodyModalContainer>
      </ModalDefault>
    </FooterContainer>
  );
};
