
import styled from 'styled-components';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 8px;
  height: 150px;
  max-height: 150px;
  background-color: #F6F6F6;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 8px;
  height: 150px;
  color: ${(props) => props.theme.colors.grey80}; 
  max-height: 150px;
  background-color: #F6F6F6;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;
export const LoadingSVG = styled.img`
  width: 20px;
  height: 20px;
`
export const LoadingMessage = styled.p`
  font-size: 12px;
  margin: 0 0 0 10px;
`;

export const AlertMessage = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  border-radius: 4px;
  font-size: 12px;
  color: #4a4a4a;
`;

export const AlertIcon = styled.span`
  margin-right: 8px;
  color: #f39c12;
`;

export const AlertText = styled.span`
  flex-grow: 1;
`;

export const AlertLink = styled.a`
  color: #007bce;
  font-weight: bold;
  display: flex;
  align-items:center;
  gap: 2px;
  text-decoration: underline;
  cursor:pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const PriceDetails = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
`;

export const PriceItem = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 12px;
  color: #4a4a4a;
`;

export const PriceLabel = styled.div`
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: normal;
  color: ${(props) => props.theme.colors.grey80};
`;

export const PriceValueContainer = styled.div`
  display: flex;
  margin-left: 10px;
  margin-right: 5px;
  justify-content: space-between;
  flex-direction: column;
`;

export const PriceValue = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: ${(props) => props.color || props.theme.colors.Dark};
`;

export const Equal = styled.img`

`;