import React from "react";
import { useHistory } from "react-router-dom";
import {BackButtonContainer,ArrowIcon} from "./button-back.atom.style" 

interface IProps {
  title?: string;
  backScreen?: any;
}


export const ButtonBackAtom: React.FC<IProps> = (props) => {
  const history = useHistory();

  return (
    <BackButtonContainer  onClick={() => history.push(props.backScreen)}>
      <ArrowIcon />
      {props.title}
    </BackButtonContainer >
  );
};
