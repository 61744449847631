import { VehicleEquipment } from "../../../models";
import { kbbHttp, useRequest } from "../config";

export const useKbb = () => {
  const { get } = useRequest(kbbHttp, "KbbMolicar");

  const GetAllEquipments = async (
    id: string | null,
    year: number | null
  ): Promise<VehicleEquipment[]> => {
    const { data } = await get(`GetAllEquipments${id ? `/${id}/${year}` : ""}`);
    return data;
  };

  return {
    GetAllEquipments,
  };
};
