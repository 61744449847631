export enum TradeInProgress {
  Awaiting,
  InProgress,
  Approved,
  WithChanges,
  ToPassOn,
  FinancingInProgress,
  FinancingRejected,
  FinancingCompleted,
}
