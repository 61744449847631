import styled from "styled-components";

interface SwitchProps {
  readonly width: number
  readonly height: number
  readonly checkedColor: string
  readonly unCheckedColor: string
}

export const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  margin: 0;
`

const circleSize = (height: number) => {
  return height - 6
}
const transformCalc = (width: number, height: number) => {
  const padding = 8
  const circle = circleSize(height)
  return width - circle - padding
}

export const StyledSwitch = styled.div<Omit<SwitchProps, 'checkedColor'>>`
  position: relative;
  width: ${({width}) => width}px;
  height: ${({height}) => height}px;
  background: ${({ unCheckedColor }) => unCheckedColor};
  border-radius: ${({height}) => height}px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 12px;
  padding-right: ${({height}) => circleSize(height)+8}px;
  transition: 300ms all;
  display: flex;
  align-items: center;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: ${({height}) => circleSize(height)}px;
    height: ${({height}) => circleSize(height)}px;
    border-radius: ${({height}) => circleSize(height)/2}px;
    top: 50%;
    right: 4px;
    background: white;
    transform: translate(0, -50%);
  }

  span {
    font-size: 12px;
    color: white;
  }
`
export const StyledInput = styled.input<Omit<SwitchProps, 'unCheckedColor'>>`
  opacity: 0;
  position: absolute;
  display: none;

  &:checked + ${StyledSwitch} {
    background: ${({ checkedColor }) => checkedColor};
    padding-left: ${({height}) => circleSize(height)+8}px;
    padding-right: 12px;

    &:before {
      transform: translate(-${({width, height}) => transformCalc(width, height)}px, -50%);
    }
  }
`
