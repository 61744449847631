import { Modal } from "react-bootstrap";
import styled from "styled-components";

export const ContainerCarousel = styled.div`
  position: relative;
  width: 100%;
  height: 97.5%;
  box-sizing: border-box;
  z-index: 0;
  margin-bottom: 0;
  padding: 0;
  border-right: 1px solid #dee2e5;
  border-bottom: 1px solid #dee2e5;

  .swiper-button-prev,
  .swiper-button-next {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff !important;
    opacity: 0.8;
    font-size: 8px !important;
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    font-size: 28px;
  }

  .swiper-button-next.swiper-button-disabled {
    display: none;
  }
  .swiper-button-prev.swiper-button-disabled {
    display: none;
  }
`;

export const VideoContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 97%;

  video {
    width: 100%;
    height: 632px;
    object-fit: cover;
  }
`;

export const IconFrame = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00b2ff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
`;

export const ImageCar = styled.img`
  width: 100%;
  height: 632px;
  max-height: 632px;
  margin-bottom: 0;
  object-fit: cover;
  z-index: 0;
`;

export const ImageIcon = styled.img`
  width: 100%;
  height: 632px;
`;

export const ContainerIcon = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.Grey30};
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #dee2e5;
`;

export const StyledModal = styled(Modal)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .modal-content {
    border: none;
  }

  .modal-body {
    height: 100%;
    display: flex;
    align-items: center;
  }

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .swiper-button-prev,
  .swiper-button-next {
    padding: 30px 0 30px 0;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.2);
    color: #fff !important;
    opacity: 0.8;
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    font-size: 38px !important;
  }
  .swiper-button-next.swiper-button-disabled {
    display: none;
  }
  .swiper-button-prev.swiper-button-disabled {
    display: none;
  }

  .zoom-container {
    position: relative;
    overflow: hidden;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: auto;
`;

export const BasicVehicleInformation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 32px;
  font-weight: 700;
  color: #4a4a4a;
  font-family: Montserrat;
`;

export const AdditionalVehicleInformation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 20px;
  font-weight: 400;
  color: #4a4a4a;
  font-family: Montserrat;
`;
