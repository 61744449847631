import { HttpHelper } from "c4u-web-components";

import {
  GetPaginatedTradeInRequest,
  IGetDebtsRequest,
  IGetDebtsResponse,
  IGetPaginatedTradeInResponseModel,
  IGetPriceAdvisorDetailsResponseModel,
  IGetTradeInPricesResponseModel,
  IPostChecklistRequest,
  IProposalDetailsResponse,
  ITradeInProgressHistoryResponse,
} from "../../../models";
import { tradeinHttp, useRequest } from "../config";

export const useProductsTradein = () => {
  const { get, post } = useRequest(tradeinHttp, "ProductsTradeIn");

  const getPaginatedTradeIn = async (
    request: GetPaginatedTradeInRequest,
  ): Promise<IGetPaginatedTradeInResponseModel> => {
    const { data } = await get(`getPaginatedTradeIn${HttpHelper.BuildQueryString(request)}`);
    return data;
  };

  const getUrlsPicture = async (
    tradeInId: number,
  ): Promise<Array<{ imageId: number; url: string }>> => {
    try {
      const { data } = await get(`getUrlsPicture/${tradeInId}`);
      return data;
    } catch (error) {
      return [];
    }
  };

  const getTradeInPrices = async (
    tradeInId: number,
    equipmentsCost?: number,
  ): Promise<IGetTradeInPricesResponseModel | null> => {
    try {
      const { data } = await get(
        `getTradeInPrices/${tradeInId}${HttpHelper.BuildQueryString({
          equipmentsCost,
        })}`,
      );
      return data.pricesUpdated && data.pricesDRS ? data : null;
    } catch (error) {
      return null;
    }
  };

  const getProposalDetails = async (tradeInId: number): Promise<IProposalDetailsResponse> => {
    const { data } = await get(`getProposalDetails/${tradeInId}`);
    return data;
  };

  const getDebts = async (request: IGetDebtsRequest) => {
    const { data } = await post<IGetDebtsResponse[]>("getUpdatedDebts", request);
    return data;
  };

  const getPriceAdvisorDetails = async (
    tradeInId: number,
    equipmentsCost?: number,
  ): Promise<IGetPriceAdvisorDetailsResponseModel | null> => {
    try {
      const { data } = await get(
        `getPriceAdvisorDetails/${tradeInId}${HttpHelper.BuildQueryString({
          equipmentsCost,
        })}`,
      );
      return data.priceMin && data.priceMax && data.averagePrice && data.requestedAt
        ? data
        : null;
    } catch (error) {
      return null;
    }
  };

  const saveChecklist = async (request: IPostChecklistRequest): Promise<void> => {
    const { data } = await post("postChecklist", request);
    return data;
  };

  const postDownloadPhotos = async (tradeInId: number) => {
    const { data } = await post(`downloadFiles/${tradeInId}`, {}, { responseType: "blob" });
    return data;
  };

  const getTradeInProgressHistory = async (
    tradeInId: number,
  ): Promise<ITradeInProgressHistoryResponse[]> => {
    const { data } = await get(`getTradeInProgressHistory/${tradeInId}`);
    return data;
  };

  return {
    getPaginatedTradeIn,
    getUrlsPicture,
    getTradeInPrices,
    getProposalDetails,
    getDebts,
    getPriceAdvisorDetails,
    saveChecklist,
    postDownloadPhotos,
    getTradeInProgressHistory,
  };
};
