import styled from "styled-components";

interface IListItemsProps {
  readonly status: string;
}

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const ChecklistOption = styled.button`
  border: none;
  cursor: pointer;
  width: 100%;
  padding: 0;
  background-color: transparent;
  display: flex;
  justify-content: end;
`;

export const ChecklistUl = styled.ul`
  text-align: left;
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  width: 100%;
  opacity: 1;
  background-color: white;
  margin-top: 5px;

  li {
    padding: 8px;
    cursor: pointer;

    &[aria-disabled="true"] {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
`;

export const ListItem = styled.div<IListItemsProps>`
  font-size: 12;
  font-weight: 400;
  background-color: #f2f2f3;
  border-radius: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 2px 6px;
  font-family: ${({ theme }) => theme.fontFamily.OpenSans};

  ::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: ${props => props.status};
  }
`;
