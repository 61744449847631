import styled from "styled-components";

export const ContainerDealerMolecule = styled.div`
  hr {
    width: 100%;
    color: #dee2e5;
    border: 0;
    border-top-width: 0px;
    border-top-style: none;
    border-top-color: currentcolor;
    border-top: 1px solid #0000001a;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
`;

export const CleanFiltersButton = styled.button`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  background: none;
  border: none;
  outline: none;

  text-transform: uppercase;
  color: ${(props) => props.theme.colors.BlueNavy};

  cursor: ${(props) => (props.onClick ? "pointer" : "default")};
  opacity: ${(props) => (props.onClick ? 1 : 0.4)};

  :hover {
    text-decoration-line: underline;
  }

  :disabled {
    cursor: not-allowed;
    color: ${(props) => props.theme.colors.Grey74};
  }
`;
