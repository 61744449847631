import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
`;

export const ContainerCardLead = styled.div`
  width: 100%;
  height: 275px;

  border: 1px solid #dee2e5;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 10px;
  gap: 10px;

  @media (max-width: 641px) {
    height: 175px;
  }
`;

export const EmptyList = styled.span`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-size: 16px;
  color: ${({ theme }) => theme.colors.Grey74};
  padding: 30px;
`
