import { Routers } from "../models";

import { ChecklistPage, ListLeadsPage } from "../ui/pages";
import { MainTemplate } from "../ui/templates";

export const paths = {
  home: () => `/`,
  listLead: () => `/list-leads`,
  checkList: () => `/checklist`,
};

export const routes = [
  {
    path: paths.listLead(),
    component: ListLeadsPage,
    template: MainTemplate,
    title: "ListLeads",
  },
  {
    path: `${paths.checkList()}/:tradeInId`,
    component: ChecklistPage,
    template: MainTemplate,
    title: "ChecklistLeads",
  },
] as Routers[];
