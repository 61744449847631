import styled from "styled-components";

export const PriceInformation = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  height: 100%;
`;

export const FinalAcquisitionPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

export const TradeInPricesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 12px;

  p {
    font-family: ${(props) => props.theme.fontFamily.OpenSans};
    font-size: 12px;
    font-weight: 400;
    color: ${(props) => props.theme.colors.MediumGray};
    margin: 0px;
    padding: 0px 40px;

    @media (max-width: 1200px) {
      padding: 0px 13px;
    }

    @media (max-width: 991px) {
      padding: 0px 20px;
    }

    @media (max-width: 841px) {
      font-size: 8px;
    }
  }
`;

export const TradeInPrices = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  img {
    width: 35px;

    @media (max-width: 820px) {
      width: 25px;
  }
  }
`;

export const Title = styled.span`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;

  color: ${(props) => props.theme.colors.BlueNavy};

  @media (max-width: 991px) {
    white-space: nowrap;
    font-size: 12px;
    line-height: 13px;
  }

  @media (max-width: 840px) {
    font-size: 10px;
  }
`;

export const Price = styled.span`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  word-break: break-all;

  color: ${(props) => props.theme.colors.BlueNavy};

  @media (max-width: 1237px) {
    font-size: 15px;
    line-height: 13px;
  }

  @media (max-width: 991px) {
    font-size: 14px;
    line-height: 13px;
  }

  @media (max-width: 840px) {
    font-size: 12px;
    line-height: 13px;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  flex-direction: column;
  gap: 10px;
  position: absolute;
`;
