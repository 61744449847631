import styled from "styled-components";

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 670px;
  background-color: #fff;
  margin: 0 0 20px 0;

  @media (max-width: 1000px) {
    flex-direction: column;
    height: auto;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
  }
`;

export const MainImage = styled.div`
  width: 50%;
  height: 650px;
  padding-bottom: 0;
  min-height: 650px;
  max-height: 650px;

  @media (max-width: 1000px) {
    width: 100%;
    height: auto;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

export const AddedDate = styled.p`
  display: flex;
  align-items: center;
  font-size: 12px;
  align-self: flex-end;
  color: #888;
  gap: 4px;
  margin: 0 0 10px 0;

  @media (max-width: 1280px) {
    font-size: 9px;
  }
`;

export const IconCalendar = styled.img`
  width: 10px;
  height: 11px;
  margin: 0 2px 2px 0;
`;

export const StyledP = styled.p`
  font-size: 12px;
  margin: 0 0 0 6px;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  padding: 20px;
  margin-bottom: 18px;
  width: 50%;
  justify-content: flex-end;

  @media (max-width: 1000px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const VehicleInformation = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  width: 100%;
  font-size: 24px;
  margin: 0 0 10px 0;
  padding: 0;
  color: ${props => props.theme.colors.BlueNavy};
  font-weight: bold;
  font-family: Montserrat;

  @media (max-width: 1280px) {
    font-size: 15px;
  }
`;

export const Year = styled.span`
  font-size: 14px;
  padding: 2px 8px;
  color: ${props => props.theme.colors.Dark};
  font-weight: normal;
  font-family: Montserrat;

  @media (max-width: 1280px) {
    font-size: 9px;
  }
`;

export const PlateAndPrice = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 1000px) {
    flex-direction: column;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ErrorMessage = styled.p`
  margin: 0;
  font-size: 10px;
  color: red;
`;

export const InputContainer = styled.div`
  width: 30%;
  height: 60px;

  @media (max-width: 1000px) {
    width: 100%;
    margin-top: 10px;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 10px;
  }
`;

export const Description = styled.p`
  font-size: 14px;
  width: 100%;
`;

export const Plate = styled.label`
  font-size: 14px;
  display: flex;
  margin-bottom: 5px;
  color: ${props => props.theme.colors.Dark};
  flex-direction: column;
`;

export const PlateValue = styled.p`
  font-size: 16px;
  margin: 0 0 5px 0;
  color: ${props => props.theme.colors.Dark};
  font-weight: bold;
`;

export const PriceValue = styled.p`
  font-size: 16px;
  color: ${props => props.theme.colors.Dark};
  font-weight: bold;
`;

export const Price = styled.label`
  font-size: 14px;
  color: ${props => props.theme.colors.Dark};
  margin-left: 20px;

  @media (max-width: 1000px) {
    margin-left: 0;
  }

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

export const Renavam = styled.label`
  font-size: 14px;
  display: flex;
  color: ${props => props.theme.colors.Dark};
  flex-direction: column;
`;

export const RenavamValue = styled.p`
  font-size: 16px;
  color: ${props => props.theme.colors.Dark};
  font-weight: bold;
`;

export const VehicleDebts = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  margin-top: 10px;
  padding: 20px 0px 20px 20px;
  border-radius: 4px;
  width: 100%;
  font-size: 12px;
  background-color: #f6f6f6;
  color: ${props => props.theme.colors.grey80};

  @media (max-width: 1000px) {
    flex-direction: column;
    padding: 10px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 10px;
  }
`;

export const Decision = styled.div`
  display: flex;
  flex-direction: column;

  margin: 10px 0 10px 0;
  width: 100%;
  height: 150px;
  max-height: 150px;
  border: 1px solid #dee2e5;
  border-radius: 4px;
`;
export const DecisionVehicle = styled.div`
  width: 80%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const DecisionTitle = styled.p`
  font-size: 14px;
  margin: 10px 20px 0 20px;
  font-weight: bold;
  color: #a8aaac;
  font-family: Open Sans;

  @media (max-width: 1000px) {
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;

export const DecisionDescription = styled.p`
  font-size: 12px;
  font-family: Open Sans;
  color: #808285;
  margin: 0;
  margin-left: 4px;
`;

export const DecisionImage = styled.img`
  width: 30%;
  height: auto;
  object-fit: cover;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 6px;

  @media (max-width: 1000px) {
    height: 80px;
  }

  @media (min-width: 1700px) {
    margin-top: 0;
  }

  @media (min-width: 1800px) {
    width: 25%;
  }

  @media (min-width: 2000px) {
    width: 18%;
  }

  @media (min-width: 2200px) {
    width: 12%;
  }

  @media (min-width: 2400px) {
    width: 6%;
  }
`;

export const DecisionDetails = styled.div`
  display: flex;
  margin-left: 20px;
  align-items: center;
  gap: 10px;

  @media (max-width: 1000px) {
    margin-left: 0;
    flex-direction: row;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    flex-direction: row;
  }
`;

export const DecisionName = styled.p`
  font-size: 14px;
  display: flex;
  flex-direction: row;
  font-weight: bold;
  margin-left: 4px;
  margin-top: 10px;
  margin-bottom: 0;
  width: 100%;
  padding: 0px;
  font-family: Open Sans;
  color: ${props => props.theme.colors.Dark};

  @media (max-width: 1300px) {
    font-size: 0.8em;
  }

  @media (max-width: 1200px) {
    font-size: 0.6em;
  }

  @media (max-width: 1000px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const DecisionPrice = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin-top: 8px;
  width: 40%;
  align-items: flex-start;

  @media (max-width: 1020px) {
    width: 100%;
    flex-direction: column;
    padding: 10px 0;
  }

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    padding: 10px 0;
  }
`;

export const LabelsPrice = styled.p`
  font-size: 11px;
  margin: 0;
  font-weight: bold;
  color: ${props => props.theme.colors.grey80};
  font-family: Open Sans;
  white-space: nowrap;

  @media (max-width: 1300px) {
    font-size: 0.9em;
    white-space: nowrap;
  }

  @media (max-width: 1000px) {
    font-size: 0.8em;
    white-space: nowrap;
  }
`;

export const DecisionValue = styled.label`
  line-height: 22px;
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.colors.Dark};
  margin-right: 10px;
  margin-bottom: 22px;
  font-family: Open Sans;

  @media (max-width: 1300px) {
    font-size: 0.7em;
  }

  @media (max-width: 1200px) {
    font-size: 0.6em;
  }
`;

export const FinancedValue = styled.label`
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
  margin: -22px 10px 25px 0px;
  color: ${props => props.theme.colors.Dark};
  font-family: Open Sans;

  @media (max-width: 1300px) {
    font-size: 0.7em;
  }

  @media (max-width: 1200px) {
    font-size: 0.6em;
  }
`;

export const Customer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding: 20px 0px 20px 20px;
  border: 1px solid #dee2e5;
  border-radius: 4px;
  width: 100%;

  @media (max-width: 1000px) {
    flex-direction: column;
    padding: 10px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 10px;
  }
`;

export const CustomerInfo = styled.div`
  width: 70%;

  @media (max-width: 1000px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const WhatsAppContainer = styled.div`
  width: 40%;
  margin-left: 24px;
  margin-right: 24px;

  @media (max-width: 1000px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`;

export const CustomerName = styled.label`
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 8px 8px;
  color: ${props => props.theme.colors.Dark};
`;

export const CustomerEmail = styled.label`
  margin-left: 10px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${props => props.theme.colors.grey80};
  gap: 5px;

  path {
    fill: #4a4a4a;
  }
`;

export const CustomerPhone = styled.label`
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 12px;
  color: ${props => props.theme.colors.grey80};
  gap: 5px;

  path {
    fill: #4a4a4a;
  }
`;

export const ProposalIdentifier = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    font-size: 14px;
    color: #4a4a4a;
    font-family: Montserrat;

    @media (max-width: 1407px) {
      font-size: 12px;
    }

    @media (max-width: 1200px) {
      font-size: 8px;
    }
  }
`;

export const BoxInformationVehicle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InformationVehicle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DataAboutProposal = styled.div`
  display: flex;
  flex-direction: column;
  width: 35%;
  gap: 10px;
  align-items: flex-end;
`;
